export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return {
        categories: action.payload,
      };
    case 'OFFERS':
      return {
        offers: action.payload,
      };
    case 'CATEGORY_PRODUCTS':
      return {
        products: action.payload,
      };
    case 'ADDED_TO_CART':
      return {
        cart_updated: new Date().getTime(),
        cart_updated_message: 'Cart updated successfully',
        item: action.payload,
      };
    case 'REMOVE_FROM_CART':
      return {
        cart_updated: new Date().getTime(),
        cart_updated_message: 'Cart updated successfully',
      };
    case 'WISH_LIST_UPDATED':
      return {
        wish_list_updated: new Date().getTime(),
        wish_list: action.payload,
      };
    case 'QUOTE_POPUP':
      return {
        get_quote: action.payload,
      };
    case 'GET_WISHLIST':
      return {
        wish_list: action.payload,
      };
    case 'REQUEST_QUOTE':
      return {
        quote_request: action.payload,
      };

    case 'GLOBAL_SEARCH':
      return {
        _search: action.payload,
      };
    case 'USER_CART':
      return {
        cart: action.payload,
      };
    case 'CHECKOUT_CART':
      return {
        checkout_cart: action.payload,
      };
    case 'PRODUCT_DETAIL':
      return {
        detail: action.payload,
      };
    case 'AJAX_QUICK_VIEW':
      return {
        ajax_quick_view: action.payload,
      };
    case 'CHECKOUT':
      return {
        checkout: action.payload || -1,
        cart_updated: new Date().getTime(),
        cart_updated_message: 'Cart updated successfully',
      };
    case 'FILTER_OPTIONS':
      return {
        filter_options: action.payload,
      };
    case 'GET_PORODUCTS':
      return {
        shop_products: action.payload,
      };

    default:
      return state;
  }
};
