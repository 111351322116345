import React from 'react';
import config from '../../../config/config';
import Helpers from '../../../util/helpers' ;
export const Price = (props) => {
  let actualPrice = props.price.retail || props.price.retail_price,
      currency = config.currency;
  return (
    <React.Fragment>
    { Helpers.getCurrentUser() &&(<div className="product-d-block product_price">
      <span className="price">{`${currency}${props.price.effective_price}`}</span>
      {!actualPrice || (
        <React.Fragment>
          <del>{`${currency}${actualPrice}`}</del>
          <div className="on_sale">
            <span>
              {(
                ((actualPrice - props.price.effective_price) / actualPrice) *
                100
              ).toFixed(0)}
              % Off
            </span>
          </div>
        </React.Fragment>
      )}
    </div>)}
    </React.Fragment>
  );
};
