import React from 'react';
import { Link } from 'react-router-dom';
export const ConfirmationPopup = (props) => {
  return (
    <div className="modal-content-small">
      <div className="modal-body">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">
            <i className="ion-ios-close-empty"></i>
          </span>
        </button>
        <div className="row no-gutters">
          <div className="col-sm-12">
            <div className="popup_content">
              <div className="popup-text">
                <div className="heading_s4">
                  <h4>Your request has been processed successfully</h4>
                </div>
                <p>Our reprsentative will contact you shortly</p>
              </div>
              <form method="post">
                <div className="form-group">
                  {props.onClose && (
                    <button
                      className="btn btn-fill-line btn-block text-uppercase rounded-0"
                      type="button"
                      onClick={props.onClose}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
