import {profileService} from '../services/profile.service';
export const profileActions = {
    getAddress: () => (dispatch) => {
    profileService
      .getAddress()
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'PROFILE_ADDRESS',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });

  },
  getOrders: ( params ) => (dispatch) => {
    profileService
      .getOrders(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'PROFILE_ORDERS',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
};
