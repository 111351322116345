import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { ShopConfig } from '../../../config/shop';
import { Link } from 'react-router-dom';
const VariantSelector = (props) => {
  return (
    <span
      className={`${props.selected ? 'active' : ''}${
        props.disbaled ? ' disabled' : ''
      }`}
      onClick={props.disbaled ? () => {} : props.onSelect}
      data-id={props.id}
      data-value={props.value}
    >
      {props.label}
    </span>
  );
};
export class AddToCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variants: this.getVariants(),
      labels: this.getItem('attribute_field_names', {}),
      quantity: ShopConfig.MIN_TO_CART,
      enableCartButton: false,
      id: this.props.id,
      ...this.getInitialAttrs(),
    };
    this.added = [];
    this.initialVals = true;
  }
  static getDerivedStateFromProps(nextProps) {
    const { cart_updated } = nextProps.products;
    if (cart_updated) {
      return {
        ajax: !1,
      };
    }
    return null;
  }
  getInitialAttrs() {
    if (!this.trace) {
      this.trace = this.getItem('trace', {});
    }
    if (this.trace.hasOwnProperty(this.props.id)) {
      return this.trace[this.props.id];
    }
    return {};
  }
  getVariants() {
    this.trace = this.getItem('trace', {});
    if (!Object.keys(this.trace).length) {
      return this.trace;
    }
    let variants = {};
    Object.keys(this.trace).map((key) => {
      let product = this.trace[key];
      Object.keys(product).map((attr) => {
        if (!variants.hasOwnProperty(attr)) {
          variants[attr] = [];
        }
        let val = product[attr];
        if (variants[attr].indexOf(val) === -1) {
          variants[attr].push(val);
        }
      });
    });
    return variants;
  }
  getItem(key, def = []) {
    if (!this.props.variants || !this.props.variants.hasOwnProperty(key)) {
      return def;
    }
    return this.props.variants[key];
  }
  close = () => {
    typeof this.props.onClose === 'function' && this.props.onClose();
  };
  onSelect = (event) => {
    let target = event.target,
      id = target.getAttribute('data-id'),
      value = target.getAttribute('data-value');
    if (id && value) {
      let obj = {};
      if (this.initialVals) {
        let keys = Object.keys(this.state.variants).map((attr) => {
          obj[attr] = '';
        });
        this.initialVals = !1;
      }
      obj[id] = value;
      if (this.added.indexOf(id) === -1) {
        this.added.push(id);
      }
      this.setState(
        {
          ...obj,
        },
        () => {
          if (Object.keys(this.state.variants).length === this.added.length) {
            this.updateVariant();
          }
        }
      );
    }
  };
  updateVariant() {
    let attrs = {};
    Object.keys(this.state.variants).map((variant) => {
      attrs[variant] = this.state[variant];
    });
    for (let trace in this.trace) {
      let obj = this.trace[trace],
        checked = 0;
      for (let key in obj) {
        if (obj[key] === attrs[key]) {
          checked++;
        }
      }
      if (checked === Object.keys(obj).length && this.props.id !== trace) {
        typeof this.props.onChange === 'function' && this.props.onChange(trace);
        break;
      }
    }
  }
  decrement = () => {
    let val = this.state.quantity;
    if (val === ShopConfig.MIN_TO_CART) {
      return;
    }
    --val;
    this.setState({
      quantity: val < ShopConfig.MIN_TO_CART ? 1 : val,
    });
  };
  incriment = () => {
    let val = this.state.quantity;
    if (val === ShopConfig.MAX_TO_CART) {
      return;
    }
    ++val;
    this.setState({
      quantity: val > ShopConfig.MAX_TO_CART ? ShopConfig.MAX_TO_CART : val,
    });
  };
  onChange = (event) => {
    let val = parseInt(event.target.value);
    this.setState({
      quantity:
        val < ShopConfig.MIN_TO_CART
          ? 1
          : val > ShopConfig.MAX_TO_CART
          ? ShopConfig.MAX_TO_CART
          : val,
    });
  };
  onKeyPress = (event) => {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return (event.returnValue = !1);
    }
    return true;
  };
  onCutOrPaste = (event) => {
    event.preventDefault();
    return (event.returnValue = !1);
  };
  check = (key, val) => {
    if (!this.added.length) {
      return !1;
    }
    if (this.state[key] && val !== this.state[key]) {
      return !0;
    }
    let status = !1;
    for (let traced in this.trace) {
      if (status) {
        return;
      }
      let expected = this.added.length,
        tracedObj = this.trace[traced],
        checked = 0;
      this.added.map((name) => {
        if (tracedObj[name] === this.state[name] && tracedObj[key] === val) {
          ++checked;
        }
      });
      if (checked === expected) {
        status = !0;
        break;
      }
    }
    return status ? !1 : !0;
  };
  addToCart = () => {
    this.setState(
      {
        ajax: !0,
      },
      () => {
        this.props.addTocart({
          url: this.props.url,
          quantity: this.state.quantity,
        });
      }
    );
  };
  getQuote = () => {
    this.props.getQuote({
      id: this.props.product,
    });
  };
  wishList = () => {
    this.props.addToWishlist &&
      'function' === typeof this.props.addToWishlist &&
      this.props.addToWishlist(this.props.id);
  };
  render() {
    return (
      <React.Fragment>
        {this.state.ajax && <div className="AjaxOverlay"></div>}
        {this.state.variants &&
          Object.keys(this.state.variants).map((item, index) => {
            return (
              <div
                className="product-d-block pr_switch_wrap"
                key={`variant_${item}`}
              >
                <span className="switch_lable">{this.state.labels[item]}</span>
                <div className="product_size_switch">
                  {this.state.variants[item].map((obj) => {
                    return (
                      <VariantSelector
                        key={`button_${item}_${obj}_${this.state.refreshId}`}
                        selected={this.state[item]}
                        selected={this.state[item] === obj}
                        onSelect={this.onSelect}
                        id={item}
                        value={obj}
                        label={obj}
                        disbaled={this.check.call(this, item, obj)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        <div className="product-d-block cart_extra">
          {this.props.user && this.props.user.auth && (
            <div className="cart-product-quantity">
              <div className="quantity">
                <input
                  type="button"
                  onClick={this.decrement}
                  className="minus"
                  value="-"
                />
                <input
                  type="text"
                  name="quantity"
                  title="Qty"
                  className="qty"
                  size="4"
                  value={this.state.quantity}
                  onChange={this.onChange}
                  onKeyPress={this.onKeyPress}
                  onPaste={this.onPaste}
                  onCut={this.onCutOrPaste}
                />
                <input
                  type="button"
                  onClick={this.incriment}
                  className="plus"
                  value="+"
                />
              </div>
            </div>
          )}
          <div className="product-d-block cart_btn">
            {this.props.user && this.props.user.auth && (
              <React.Fragment>
                <button
                  onClick={this.addToCart}
                  className="btn btn-fill-out btn-addtocart"
                  type="button"
                >
                  <i className="icon-basket-loaded"></i> Add to cart
                </button>
                <Link
                  className="btn btn-fill-out btn-addtocart"
                  type="button"
                  to={`/buy-now/checkout/${this.state.quantity}/${this.props.id}/`}
                >
                  <i className="icon-basket-loaded"></i> Buy Now
                </Link>
              </React.Fragment>
            )}

            {!this.props.user ||
              (!this.props.user.auth && (
                <button
                  onClick={this.getQuote}
                  className="btn btn-fill-out btn-addtocart"
                  type="button"
                >
                  <i className="icon-basket-loaded"></i> Get Quote
                </button>
              ))}

            <a className="add_wishlist" onClick={this.wishList}>
              <i className="icon-heart"></i>
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
AddToCart.propTypes = {
  variants: PropTypes.object,
  id: PropTypes.number,
};
const mapStateToProps = (state) => ({
  products: state.products,
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  addTocart: (data) => {
    return dispatch(productActions.addTocart(data));
  },
  buyNow: (data) => {
    return dispatch(productActions.buyNow(data));
  },
  getQuote: (data) => {
    return dispatch(productActions.quotePopup(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
