import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MiniCart from '../../static/mini-cart/MiniCart';
import Helpers from '../../../util/helpers';
import { userActions } from '../../../actions/userActions';
import SearchBox from '../../static/saerch/SearchBox';
const MOBILE_MENU = 991;
export class HeaderSecondary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      categories: [],
      category: '',
      query: '',
    };
  }
  static getDerivedStateFromProps(nextProps) {
    return null;
  }
  expandMenu = () => {
    this.setState({
      expanded: !this.state.expanded ? true : false,
    });
  };
  update = (event) => {
    this.setState({
      [event.target.getAttribute('name')]: event.target.value,
    });
  };
  logout = () => {
    this.props.logout();
  };
  render() {
    return (
      <header
        className="header_wrap fixed-top header_with_topbar HeaderSecondary"
        ref={(node) => (this.node = node)}
      >
        <div className="top-header light_skin bg_dark d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                  <ul className="contact_detail text-center text-lg-left">
                    <li className={`top-search-box`}>
                      <SearchBox style={`dark-mode`} />
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <span>123-456-7890</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-center text-md-right">
                  <ul className="header_list">
                    <li>
                      <Link to={'/user/wishlist/all/'}>
                        <i className="ti-heart"></i>
                        <span>Wishlist</span>
                      </Link>
                    </li>
                    {(!this.props.user || !this.props.user.auth) && (
                      <React.Fragment>
                        <li>
                          <Link to={'/partner-with-us'}>
                            <i className="ti-control-shuffle"></i>
                            <span>Partner with us</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/login'}>
                            <i className="ti-user"></i>
                            <span>Login</span>
                          </Link>
                        </li>
                      </React.Fragment>
                    )}
                    {this.props.user && this.props.user.auth && (
                      <React.Fragment>
                        <li>
                          <Link to={`/user/my-account/orders`}>
                            <i className="ti-user"></i>
                            <span>{this.props.user.auth.username}</span>
                          </Link>
                        </li>
                        <li>
                          <a onClick={this.logout}>
                            <i className="ti-power-off"></i>
                            <span>Logout</span>
                          </a>
                        </li>
                      </React.Fragment>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_header dark_skin main_menu_uppercase">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <Link className="navbar-brand" to="/">
                <img
                  className="logo_light"
                  src={require('../../../images/assets/logo-dark.png')}
                  alt="logo"
                />
                <img
                  className="logo_dark"
                  src={require('../../../images/assets/logo-dark.png')}
                  alt="logo"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                onClick={this.toggleMobileMenu}
              >
                <span className="ion-android-menu"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="dropdown">
                    <Link className="nav-link" to={'/'}>
                      Home
                    </Link>
                  </li>
                  <li className="dropdown">
                    <Link className="nav-link" to={'/products/all'}>
                      Shop
                    </Link>
                  </li>
                  <li className="dropdown dropdown-mega-menu">
                    <Link
                      className="dropdown-toggle nav-link"
                      to={`/products/all`}
                      data-toggle="dropdown"
                    >
                      Categories
                    </Link>
                    <div className="dropdown-menu">
                      <ul className="mega-menu d-lg-flex">
                        {this.props.categories &&
                          this.props.categories.length &&
                          this.props.categories.map((item, index) => {
                            return (
                              <li
                                className="mega-menu-col col-lg-3"
                                key={`HeaderSecondary_Cat_menu_${index}`}
                              >
                                <ul>
                                  <li className="dropdown-header">
                                    <Link to={`/products/${item.slug}`}>
                                      {item.name}
                                    </Link>
                                  </li>
                                  {item.children &&
                                    item.children.length > 0 &&
                                    item.children.map((item, childIndex) => {
                                      return (
                                        <li
                                          key={`HeaderSecondary_Child_Cat_menu_${childIndex}`}
                                        >
                                          <Link
                                            className="dropdown-item nav-link nav_item"
                                            to={`/products/${item.slug}/`}
                                          >
                                            {item.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                  <li className="dropdown">
                    <Link className="nav-link" to={`/user/my-account/orders`}>
                      My Account
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="navbar-nav attr-nav align-items-center">
                <li>
                  <a
                    onClick={this.props.mobileMenu}
                    className="nav-link pr_search_trigger"
                  >
                    <i className="linearicons-magnifier"></i>
                  </a>
                </li>
                <MiniCart />
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

HeaderSecondary.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(userActions.logout());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSecondary);
