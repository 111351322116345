import React from 'react';
import PropTypes from 'prop-types';
export const TextBlock = (props) => {
  let text = props.text,
    max = props.max || 100;
    let uid = `content_${new Date().getTime()}`,
    wrapper = document.createElement('div');
  wrapper.innerHTML = text;
  let ele = wrapper.querySelectorAll('p'),
    length = ele.length,
    updated = !1;
  for (let i = 0; i < length; i++) {
    let obj = ele[i],
      txt = obj.textContent;
    if (updated) {
      obj.classList.add('ellipsis-c-h');
      wrapper.appendChild(obj);
      continue;
    }
    if (txt.length > max) {
      updated = !0;
      let span = document.createElement('span');
      span.setAttribute('class', 'ellipsis-content-trim');
      span.innerText = `${txt.substr(0, max)}`;
      obj.innerHTML = '';
      obj.appendChild(span);
      let readMore = document.createElement('span');
      readMore.textContent = '...Read More';
      readMore.setAttribute('aria-owns', uid);
      readMore.setAttribute('class', 'read-more-button');
      obj.appendChild(readMore);
      let remaining = document.createElement('span');
      remaining.setAttribute('class', 'ellipsis-c-h');
      remaining.innerText = txt.substring(max, txt.length);
      obj.appendChild(remaining);
      wrapper.appendChild(obj);
    }
    max -= txt.length;
  }

  if (updated) {
    let readLess = document.createElement('span');
    readLess.setAttribute('aria-owns', uid);
    readLess.setAttribute('class', 'read-less-btn');
    readLess.textContent = 'Read Less';
    wrapper.appendChild(readLess);
  }
  wrapper.setAttribute('class', 'ellipsis-wrapper');
  wrapper.setAttribute('id', uid);
  return (
    <div
      className="ellipsis-block"
      onClick={(event) => {
        let target = event.target;
        if (
          target.classList.contains('read-more-button') ||
          target.classList.contains('read-less-btn')
        ) {
          let ele = document.getElementById(target.getAttribute('aria-owns'));
          if (ele) {
            ele.setAttribute(
              'aria-expanded',
              target.classList.contains('read-less-btn') ? 'false' : 'true'
            );
          }
        }
      }}
      dangerouslySetInnerHTML={{ __html: wrapper.outerHTML }}
    ></div>
  );
};
TextBlock.propTypes = {
  text: PropTypes.string,
  max: PropTypes.number,
};
