import PropTypes from 'prop-types';
import React from 'react';
import { productActions } from '../../../actions/product.action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MyAccountProfile from './Profile';
import MyAccountOrders from './Orders';
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: this.getType(),
    };
  }
  getType() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.section
    ) {
      return this.props.match.params.section;
    }
  }
  componentDidUpdate(prevProps, state) {
    if (state.section !== this.getType()) {
      this.setState({
        section: this.getType(),
      });
    }
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        refresh_id: new Date().getTime(),
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="dashboard_menu">
                  <ul className="nav nav-tabs flex-column" role="tablist">
                    <li className="nav-item ">
                      <Link
                        className={`nav-link ${
                          this.state.section === 'orders' ? 'active' : ''
                        }`}
                        role="tab"
                        to={`/user/my-account/orders`}
                        aria-controls="orders"
                        aria-selected="false"
                      >
                        <i className="ti-shopping-cart-full"></i>Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          this.state.section === 'profile' ? 'active' : ''
                        }`}
                        role="tab"
                        aria-controls="orders"
                        aria-selected="false"
                        to={`/user/my-account/profile`}
                      >
                        <i className="ti-shopping-cart-full"></i>Profile
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        className={`nav-link ${this.state.section === 'address' ? 'active':''}`}
                        to={`/user/my-account/address`}
                        role="tab"
                        aria-controls="address"
                        aria-selected="true"
                      >
                        <i className="ti-location-pin"></i>My Address
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="tab-content dashboard_content">
                  <div
                    className="tab-pane fade active show"
                    id="orders"
                    role="tabpanel"
                    aria-labelledby="orders-tab"
                    key={`Section_${this.state.section}`}
                  >
                    {this.state.section === 'orders' && (
                      <MyAccountOrders p={this.state.refresh_id} />
                    )}
                    {this.state.section === 'profile' && <MyAccountProfile />}
                    {/* { this.state.section === 'address' && <MyAccountAddress /> } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
MyAccount.propTypes = {};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getOffers: () => {
    dispatch(productActions.getOffers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
