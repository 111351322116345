import PropTypes from 'prop-types';
import React from 'react';
import { profileActions } from '../../../actions/profile.action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { history } from '../../../store/configureStore';
import Helpers from '../../../util/helpers';
import config from '../../../config/config';
const RESULTS_PER_PAGE = 15;
class MyAccountOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      count: 0,
      ajax: !1,
    };
  }
  componentDidMount() {
    this.props.getOrders(this.getUrlParams());
  }
  static getDerivedStateFromProps(nextProps) {
    const { orders } = nextProps.profile;
    if (orders && orders.count) {
      return {
        count: orders.count,
      };
    }
    return null;
  }
  getUrlParams() {
    return Object.assign(
      {},
      { page: 1, page_size: RESULTS_PER_PAGE },
      Helpers.praseQuery(true, true)
    );
  }
  componentDidUpdate(prevProps) {
    if ( prevProps.p !== this.props.p) {
      let params = this.getUrlParams();
      this.setState(
        {
          page: params.page,
          ajax : !0
        },
        () => {
          this.props.getOrders(params);
        }
      );
      return ;
    }
    if(this.state.ajax){
      if( !prevProps.profile.id || (this.props.profile.id !== prevProps.profile.id) ){
        this.setState({
          ajax : !1
        })
      }
    }
  }
  pagination = (page) => {
    history.push(`/user/my-account/orders/?page=${page}`);
  }
  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h3>Orders</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {this.state.ajax  && <div className="OrderOverlay"></div>}
              <table className="table">
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.profile &&
                    this.props.profile.orders &&
                    this.props.profile.orders.results &&
                    this.props.profile.orders.results.length &&
                    this.props.profile.orders.results.map((item, index) => {
                      return (
                        <tr key={`Order_${index}`}>
                          <td>#W{item.id}C</td>
                          <td>{new Date(item.date_placed).toDateString()}</td>
                          <td>{item.status}</td>
                          <td>{config.currency}{item.total_incl_tax}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div>
              <Pagination
                pageRangeDisplayed={3}
                itemClass={'page-item'}
                linkClass={'page-link'}
                activeClass={'active'}
                activeLinkClass={'active-page'}
                innerClass={
                  'pagination mt-3 justify-content-center pagination_style1'
                }
                activePage={parseInt(this.state.page)}
                itemsCountPerPage={RESULTS_PER_PAGE}
                totalItemsCount={this.state.count}
                onChange={this.pagination}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
MyAccountOrders.propTypes = {};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getOrders: (params) => {
    dispatch(profileActions.getOrders(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrders);
