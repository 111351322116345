import React from 'react';
import { Link } from 'react-router-dom';
const cartIcon = require('../../../images/assets/emptycart.png');
export const PageNotFound = (props) => {
  return (
    <div className="section section-404">
      <div className="error_wrap">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-lg-first">
              <div className="text-center">
                <div className="error_txt">
                  <img src={cartIcon} />
                </div>
                <h5 className="mb-2 mb-sm-3">No products found</h5>
                <p>
                  {props.desc ||
                    ' The page you are looking for was moved, removed, renamed or might never existed.'}
                </p>
                <Link to={`../../products/all/`} className="btn btn-fill-out">
                  Browse all Categories
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
