import PropTypes from 'prop-types';
import React from 'react';
import { productActions } from '../../../actions/product.action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Helpers from '../../../util/helpers';
import MyAccountAddress from './Address';
class MyAccountProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user : Helpers.getCurrentUser()
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="card" style={{
          marginBottom : 20
        }}>
          <div className="card-header">
            <h3>Account Details</h3>
          </div>
          <div className="card-body">
            <p>
              {this.state.user.email}
            </p>
          </div>
        </div>
        <MyAccountAddress />
      </React.Fragment>
    );
  }
}
MyAccountProfile.propTypes = {};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getOffers: () => {
    dispatch(productActions.getOffers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountProfile);
