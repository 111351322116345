import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { userActions } from '../../../actions/userActions';
import config from '../../../config/config';
import { Link } from 'react-router-dom';
import Helpers from '../../../util/helpers';
import { PageNotFound } from '../../static/404/404';
export class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      cart_updating: null,
      cart_refreshing: false,
      cart_refresh_key: '',
    };
  }
  componentDidMount() {
    this.props.getCart();
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { cart, cart_updated } = nextProps.products;
    if (cart) {
      return {
        cart: cart,
      };
    }
    if (cart_updated) {
      return {
        cart_refreshing: !1,
        cart_refresh_key: cart_updated 
      };
    }
    return null;
  }
  updateCart = (element, decrement = false, clear) => {
    let target = document.getElementById(
      `product_url_${element.getAttribute('data-id')}`
    );
    if (target && target.value) {
      this.setState(
        {
          cart_refreshing: true,
        },
        () => {
          this.props.addTocart({
            url: target.value,
            quantity: decrement ? -1 : 1,
          });
        }
      );
    }
  };
  decrement = (event) => {
    let current = parseInt(event.target.getAttribute('data-count')),
      parsedValue = Helpers.parseCount(--current);
    if (parsedValue) {
      this.updateCart(event.target, true);
    }
  };
  incriment = (event) => {
    let current = parseInt(event.target.getAttribute('data-count')),
      parsedValue = Helpers.parseCount(++current);
    if (parsedValue) {
      this.updateCart(event.target);
    }
  };
  clear = (event) => {
    var target = event.currentTarget;
    if (target) {
      let cartId = target.getAttribute('data-cart');
      if (cartId) {
        this.props.clear({
          cartId,
        });
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive shop_cart_table">
                  {this.state.cart_refreshing && (
                    <div className="CartUpdateOverlay"></div>
                  )}
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="product-thumbnail">&nbsp;</th>
                        <th className="product-name">Product</th>
                        <th className="product-price">Price</th>
                        <th className="product-quantity">Quantity</th>
                        <th className="product-subtotal">Total</th>
                        <th className="product-remove">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                    { (!this.state.cart || !this.state.cart.lines || !this.state.cart.lines.length) && (
                      <tr>
                        <td colSpan={6}>
                        <PageNotFound desc={'Your shopping cart is empty'}/>
                        </td>
                      </tr>
                    )}
                      {this.state.cart &&
                        this.state.cart.lines && this.state.cart.lines.length > 0 &&
                        this.state.cart.lines.map(
                          ({
                            product,
                            line_price_excl_tax,
                            line_price_incl_tax,
                            quantity,
                            url,
                            id,
                          }) => (
                            <tr key={`cart_item_${id}`} data-item-id={id}>
                              <td className="product-thumbnail">
                                <Link
                                  to={`/product/${product.slug}/${product.id}/`}
                                >
                                  <img
                                    src={product.primary_image.mobile}
                                    alt={product.name}
                                  />
                                </Link>
                              </td>
                              <td className="product-name" data-title="Product">
                                <Link
                                  to={`/product/${product.slug}/${product.id}/`}
                                >
                                  {product.name}
                                </Link>
                              </td>
                              <td className="product-price" data-title="Price">
                                {config.currency}
                                {line_price_excl_tax}
                              </td>
                              <td
                                className="product-quantity"
                                data-title="Quantity"
                              >
                                <div className="quantity">
                                  <input
                                    type="button"
                                    value="-"
                                    className="minus"
                                    onClick={this.decrement}
                                    data-id={product.id}
                                    data-count={quantity}
                                  />
                                  <input
                                    type="text"
                                    name="quantity"
                                    value={quantity}
                                    title="Qty"
                                    className="qty"
                                    size="2"
                                    data-id={product.id}
                                    readOnly={true}
                                  />
                                  <input
                                    type="hidden"
                                    value={`${product.url}`}
                                    id={`product_url_${product.id}`}
                                  />
                                  <input
                                    data-id={product.id}
                                    type="button"
                                    value="+"
                                    className="plus"
                                    onClick={this.incriment}
                                    data-count={quantity}
                                  />
                                </div>
                              </td>
                              <td
                                className="product-subtotal"
                                data-title="Total"
                              >
                                {config.currency}
                                {quantity * line_price_excl_tax}
                              </td>
                              <td
                                className="product-remove"
                                data-title="Remove"
                              >
                                <a
                                  className="RemoveCartItem"
                                  onClick={this.clear}
                                  data-id={product.id}
                                  data-cart={url}
                                >
                                  <i className="ti-close"></i>
                                </a>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="medium_divider"></div>
                <div className="divider center_icon">
                  <i className="ti-shopping-cart-full"></i>
                </div>
                <div className="medium_divider"></div>
              </div>
            </div>
            {this.state.cart && this.state.cart.lines && this.state.cart.lines.length > 0 &&(<div className="row">
             
              <div className="col-md-6">
                <div className="border p-3 p-md-4">
                  <div className="heading_s1 mb-3">
                    <h6>Cart Totals</h6>
                  </div>
                  <div className="table-responsive">
                    {this.state.cart && (
                      <table className="table" key={`cart_totals`}>
                        <tbody>
                          <tr>
                            <td className="cart_total_label">Cart Subtotal</td>
                            <td className="cart_total_amount">
                              {config.currency}
                              {this.state.cart.total_excl_tax}
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">Shipping</td>
                            <td className="cart_total_amount">Free Shipping</td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">Tax</td>
                            <td className="cart_total_amount">
                              {config.currency}
                              {this.state.cart.total_tax}
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">Total</td>
                            <td className="cart_total_amount">
                              <strong>
                                {config.currency}
                                {this.state.cart.total_incl_tax}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <Link to={'/checkout'} className="btn btn-fill-out">
                    Proceed To CheckOut
                  </Link>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Cart.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCart: () => {
    dispatch(productActions.updateCart());
  },
  addTocart: (data) => {
    return dispatch(productActions.addTocart(data));
  },
  clear: (data) => {
    return dispatch(productActions.removeFromCart(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
