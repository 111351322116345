/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
const store = configureStore();
import './styles/style.css';
import './styles/ionicons.min.css';
import './styles/responsive.css';
import './styles/all.min.css';
import './styles/custom.scss';
import './styles/linearicons.css';
import './styles/themify-icons.css';
import './styles/slick.css';
import './styles/simple-line-icons.css';
render(
  <AppContainer>
      <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
