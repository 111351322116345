import {
    Route,
    Redirect,
  } from 'react-router-dom';
  import React from 'react';
  import Home from './pages/home/Home' ;
  import Helpers from '../util/helpers' ;
const PrivateRoute = ({component: Component, ...rest}) => {
    
    return (
  
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        
        <Route {...rest} render={props => {
            // return (
            //  ?
            //     <Component {...props} />
            // :   <Home {...props} />
            if(!Helpers.getCurrentUser()){
                return <Component {...props} />
            }else{
                let exclude = ['/login','/register/check/:action'] , path = props.match.path ;
                if(exclude.indexOf( path ) > -1 ){
                    return <Home {...props} />
                }
                return <Component {...props} />
            }
        }} />
    );
}
export default PrivateRoute ;