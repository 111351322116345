import PropTypes from 'prop-types';
import React from 'react';
import { productActions } from '../../../actions/product.action';
import { connect } from 'react-redux';
import ProductSection from '../../static/product-section/ProductSection';
import ProductDetail from '../../static/product-main/ProductDetail';
class ProductDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(props.match.params.id),
    };
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { match } = nextProps;
    if (match && match.params && match.params.id) {
      if (state.id !== match.params.id && !isNaN(parseInt(match.params.id))) {
        return {
          id: parseInt(match.params.id),
        };
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="section ProductDetailPage">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ProductDetail
                  product={this.state.id}
                  key={`ProductDetail_${this.state.id}`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ProductSection
                  tittle={'Top Selling'}
                  viewAll={null}
                  name={'most_selling'}
                  settings={{
                    slidesToShow : 4 ,
                    slidesToScroll: 4,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ProductDetailPage.propTypes = {};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getProducts: (data) => {
    dispatch(productActions.getProducts(data));
  },
  ajaxQucikView: (id) => {
    dispatch(productActions.ajaxQuickView(id));
  },
  getCategories: () => {
    dispatch(productActions.getCategories());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
