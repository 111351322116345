import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { userActions } from '../../../actions/userActions';
import config from '../../../config/config';
import { Link } from 'react-router-dom';
export class MiniCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      cart_refresh_key: '',
    };
  }
  componentDidMount() {
    this.props.getCart();
  }
  static getDerivedStateFromProps(nextProps) {
    const { cart, cart_updated, cart_updated_message } = nextProps.products;
    if (cart) {
      return {
        cart: cart,
      };
    }
    if (cart_updated) {
      return {
        cart_refresh_key: cart_updated,
        cart_updated_message: cart_updated_message,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps, state) {
    if (this.props.user.auth !== prevProps.user.auth) {
      this.props.getCart();
    }
    if (this.state.cart_refresh_key) {
      if (
        !state.cart_refresh_key ||
        state.cart_refresh_key !== this.state.cart_refresh_key
      ) {
        this.props.getCart();
        this.props.toast({
          message: this.state.cart_updated_message,
          type: 'info',
        });
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <li
          className="dropdown cart_dropdown"
          data-refresh-id={this.state.cart_refresh_key}
        >
          <Link className="nav-link cart_trigger" to={`/user/cart/products`} data-toggle="dropdown">
            <i className="linearicons-cart"></i>
            {this.state.cart && (
              <span className="cart_count">
                {this.state.cart && this.state.cart.lines
                  ? this.state.cart.lines.length
                  : 0}
              </span>
            )}
          </Link>
          {this.state.cart &&
            this.state.cart.lines &&
            this.state.cart.lines.length > 0 && (
              <div className="cart_box dropdown-menu dropdown-menu-right">
                <ul className="cart_list">
                  {this.state.cart.lines &&
                    this.state.cart.lines.map(
                      ({
                        product,
                        line_price_excl_tax,
                        line_price_incl_tax,
                        quantity,
                      }) => (
                        <li key={`MiniCartItem_${product.id}`}>
                          <a className="item_remove RemoveCartItem">
                            <i className="ion-close"></i>
                          </a>
                          <Link to={`/product/${product.slug}/${product.id}/`}>
                            <img
                              src={product.primary_image.mobile}
                              alt={product.name}
                            />
                            {product.name}
                          </Link>
                          <span className="cart_quantity">
                            {quantity} x
                            <span className="cart_amount">
                              <span className="price_symbole">
                                {config.currency}
                              </span>
                            </span>
                            {line_price_incl_tax}
                          </span>
                        </li>
                      )
                    )}
                </ul>
                <div className="cart_footer">
                  <p className="cart_total">
                    <strong>Subtotal:</strong>
                    <span className="cart_price">
                      <span className="price_symbole">{config.currency}</span>
                    </span>
                    {this.state.cart.total_incl_tax}
                  </p>
                  <p className="cart_buttons">
                    <Link
                      to={`/user/cart/products`}
                      className="btn btn-fill-line view-cart"
                    >
                      View Cart
                    </Link>
                    <Link to="/checkout" className="btn btn-fill-out checkout">
                      Checkout
                    </Link>
                  </p>
                </div>
              </div>
            )}
        </li>
      </React.Fragment>
    );
  }
}

MiniCart.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCart: () => {
    dispatch(productActions.updateCart());
  },
  toast: (data) => {
    return dispatch(userActions.toast(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);
