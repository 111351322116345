import React from 'react';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { userActions } from '../../../actions/userActions';
import { Link } from 'react-router-dom';
import config from '../../../config/config';
import Helpers from '../../../util/helpers';
import { PageNotFound } from '../../static/404/404';
import { AddressBlock } from '../address/Address';
export class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: 'cod',
      ajax: !1,
      checkout: '',
    };
  }
  componentDidMount() {
    let buyNow = this.isBUyNow();
    if (buyNow) {
      this.props.buyNow({
        url: `${config.api}products/${buyNow}/`,
        quantity: this.props.match.params.quantity || 1,
      });
    } else {
      this.props.getCart();
    }
    this.props.getAddress();
  }
  isBUyNow() {
    return this.props.match &&
      this.props.match.params &&
      this.props.match.params.productId
      ? this.props.match.params.productId
      : !1;
  }
  componentDidUpdate(prevState) {
    if (prevState.checkout !== this.state.checkout) {
      Helpers.scrollToY(0);
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { checkout_cart, checkout } = nextProps.products;
    if (checkout_cart && state.checkout !== 'done') {
      return {
        checkout_cart,
      };
    }
    if (checkout) {
      if (checkout === -1 || checkout.non_field_errors) {
        return {
          checkout: 'failed',
          message:
            checkout === -1
              ? 'Something went wrong while processing your request'
              : checkout.non_field_errors.join(', '),
        };
      } else {
        return {
          checkout: 'done',
        };
      }
    }
    return null;
  }
  getOptions() {
    var obj = Object.assign({}, this.props.user.user_address),
      _config = config.razor_Pay;
    _config.prefill = {
      name: `${obj.first_name} ${obj.last_name}`,
      email: '',
      contact: obj.phone_number,
    };
    _config.amount = this.getAmount();
    _config.handler = this.paymentResponseHandler;
    return _config;
  }
  getAmount = () => {
    return this.state.checkout_cart.total_incl_tax * 100;
  };
  checkoutOptions = () => {
    let { user_address } = this.props.user;
    let cart = Object.assign({}, this.state.checkout_cart);
    let address = {
      country: user_address.country,
      first_name: user_address.first_name,
      last_name: user_address.last_name,
      line1: user_address.line1,
      line2: user_address.line2,
      line3: user_address.line3,
      line4: user_address.line4,
      notes: '',
      phone_number: user_address.phone_number,
      postcode: user_address.postcode,
      state: user_address.state,
      title: user_address.title,
    };
    let options = {
      basket: cart.url,
      guest_email: '',
      total: cart.total_incl_tax_excl_discounts,
      shipping_charge: {
        excl_tax: 0.0,
        currency: 'INR',
        incl_tax: 0.0,
        tax: 0.0,
      },
      shipping_method_code: 'free',
      shipping_address: address,
      billing_address: address,
    };
    return options;
  };
  paymentMethodChange = (event) => {
    this.setState({
      payment: event.target.value,
    });
  };
  paymentResponseHandler = (res) => {
    if (res && res.razorpay_payment_id) {
      var options = this.checkoutOptions();
      options['payment'] = {
        razor_pay: {
          amount: options.total,
          razorpay_payment_id: res.razorpay_payment_id,
          enabled: true,
          pay_balance: true,
        },
      };
      this._checkout(options);
    }
  };
  _checkout = (options) => {
    this.setState(
      {
        ajax: true,
      },
      () => {
        this.props.checkout(options);
      }
    );
  };
  COD_Chekout() {
    var options = this.checkoutOptions();
    options['payment'] = {
      cash: {
        pay_balance: true,
        enabled: true,
      },
    };
    this._checkout(options);
  }
  checkout = (event) => {
    if (this.state.payment === 'cod') {
      this.COD_Chekout();
    } else {
      this.RAZ_Checkout();
    }
  };
  RAZ_Checkout = () => {
    !this.rzp && (this.rzp = new Razorpay(this.getOptions()));
    this.rzp.open();
  };
  render() {
    return (
      <React.Fragment>
        {this.state.checkout_cart &&
          this.state.checkout_cart.lines &&
          this.state.checkout_cart.lines.length > 0 && (
            <React.Fragment>
              {this.state.checkout !== 'done' && (
                <div className="section">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-12">
                            <div className="heading_s1">
                              <h4>Billing Address</h4>
                            </div>
                            {this.props.user.user_address && (
                              <AddressBlock
                                data={this.props.user.user_address}
                              />
                            )}
                          </div>
                          <div className="col-12">
                            <div className="heading_s1">
                              <h4>Shipping Address</h4>
                            </div>
                            {this.props.user.user_address && (
                              <AddressBlock
                                data={this.props.user.user_address}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {this.state.ajax && 0 > 10 && (
                          <div className="OrderOverlay"></div>
                        )}
                        <div className="order_review">
                          <div className="heading_s1">
                            <h4>Your Orders</h4>
                          </div>
                          <div className="table-responsive order_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.checkout_cart &&
                                  this.state.checkout_cart.lines &&
                                  this.state.checkout_cart.lines.map(
                                    (item, index) => {
                                      return (
                                        <tr key={`Cart_item_list_${index}`}>
                                          <td>
                                            <Link
                                              to={`/product/${item.product.slug}/${item.product.id}`}
                                            >
                                              {item.product.name}
                                            </Link>
                                            <span className="product-qty">
                                              {` x ${item.quantity}`}
                                            </span>
                                          </td>
                                          <td>
                                            {config.currency}
                                            {item.line_price_excl_tax *
                                              item.quantity}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                              <tfoot>
                                {this.state.checkout_cart &&
                                  this.state.checkout_cart.lines && (
                                    <React.Fragment>
                                      <tr>
                                        <th>SubTotal</th>
                                        <td className="product-subtotal">
                                          {config.currency}
                                          {
                                            this.state.checkout_cart
                                              .total_excl_tax
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Shipping</th>
                                        <td>Free</td>
                                      </tr>
                                      <tr>
                                        <th>Tax</th>
                                        <td>
                                          {config.currency}
                                          {this.state.checkout_cart.total_tax}
                                        </td>
                                      </tr>

                                      <tr>
                                        <th>Total</th>
                                        <td className="product-subtotal">
                                          {config.currency}
                                          {
                                            this.state.checkout_cart
                                              .total_incl_tax_excl_discounts
                                          }
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )}
                              </tfoot>
                            </table>
                          </div>
                          <div className="payment_method">
                            <div className="heading_s1">
                              <h4>Payment</h4>
                            </div>
                            <div className="payment_option">
                              <div className="custome-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="payment_option"
                                  id="option_cod"
                                  defaultChecked={true}
                                  value="cod"
                                  onChange={this.paymentMethodChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="option_cod"
                                >
                                  Cash on Delevery
                                </label>
                              </div>
                              <div className="custome-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="payment_option"
                                  id="option_online"
                                  value="online"
                                  onChange={this.paymentMethodChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="option_online"
                                >
                                  Card / UPI
                                </label>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={this.checkout}
                            className="btn btn-fill-out btn-block"
                          >
                            Place Order
                          </button>
                          {this.state.checkout === 'failed' && (
                            <div className="CheckoutError">
                              {this.state.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.checkout === 'done' && (
                <div className="section">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <div className="text-center order_complete">
                          <i className="fas fa-check-circle"></i>
                          <div className="heading_s1">
                            <h3>Your order is completed!</h3>
                          </div>
                          <p>
                            Thank you for your order! Your order is being
                            processed and will be completed within 3-6 hours.
                            You will receive an email confirmation when your
                            order is completed.
                          </p>
                          <Link
                            href="shop-left-sidebar.html"
                            className="btn btn-fill-out"
                            to={`/products/all/`}
                          >
                            Continue Shopping
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        {!this.state.checkout_cart ||
          !this.state.checkout_cart.lines ||
          (this.state.checkout_cart.lines.length === 0 && (
            <React.Fragment>
              <PageNotFound />
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  }
}

Checkout.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCart: () => {
    dispatch(productActions.updateCart(true));
  },
  getAddress: () => {
    dispatch(userActions.getAddress());
  },
  checkout: (data) => {
    dispatch(productActions.checkout(data));
  },
  buyNow: (data) => {
    return dispatch(productActions.buyNow(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
