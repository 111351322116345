import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import Helpers from '../../../util/helpers';
export const CategoryMenu = function (props) {
  return (
    <li className="dropdown dropdown-mega-menu">
      <Link
        className={`dropdown-item nav-link ${props.menu && props.menu.children && props.menu.children.length > 0 ? 'dropdown-toggler' :''}`}
        to={`/products/${props.menu.slug}/`}
        data-toggle="dropdown"
       
      >
        <i className="flaticon-tv"></i>
        <span>{props.menu.name}</span>
      </Link>
      {props.menu && props.menu.children && props.menu.children.length > 0 && (
        <div className="dropdown-menu show">
          <ul className="mega-menu d-lg-flex">
            <li className="mega-menu-col col-lg-7">
              <ul className="d-lg-flex">
                <li className="mega-menu-col col-lg-6">
                  <ul>
                    {props.menu.children.map((childItem, childIndex) => {
                      return (
                        <li key={`menu_children_${childIndex}`}>
                          <Link
                            className="dropdown-item nav-link nav_item"
                            to={`/products/${childItem.slug}/`}
                          >
                            {childItem.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </li>
  );
};
CategoryMenu.propTypes = {
    menu : PropTypes.object
}