import React from 'react' ;
export const AddressBlock = ({ data }) => {
    return (
      <div
        style={{
          marginBottom: 30,
        }}
      >
        <div>
          {data.first_name} {data.last_name}{' '}
        </div>
        <div>
          {data.line1}, {data.line2}
        </div>
        <div>
          {data.line3}, {data.line4}
        </div>
        <div>
          {data.state}, {data.postcode}
        </div>
        <div>{data.phone_number}</div>
      </div>
    );
  };