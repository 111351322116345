import Helpers from '../util/helpers';
export default (state = {}, action) => {
  switch (action.type) {
    case 'PROFILE_ADDRESS':
      return {
        address: action.payload,
      };
    case 'PROFILE_ORDERS':
      return {
        orders: action.payload,
        id : ( new Date()).getTime()
      };
    default:
      return state;
  }
};
