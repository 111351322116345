import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Helpers from '../../../util/helpers';
import { userActions } from '../../../actions/userActions';
class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      auth_error: '',
    };
  }
  update = (event) => {
    let name = event.target.getAttribute('name') || null;
    if (!name) {
      return;
    }
    this.setState({
      [name]: (event.target.value || '').trim(),
    });
  };
  onFocusIn = (event) => {
    Helpers.focusIn(event);
  };
  onFocusOut = (event) => {
    Helpers.focusOut(event);
  };
  onInputClick = (event) => {
    Helpers.onClick(event);
  };
  submit = (event) => {
    event.preventDefault();
    if (Helpers.validateFrom(event.target)) {
      this.setState(
        {
          ajaxLoading: true,
        },
        () => {
          this.props.authenticate({
            email: this.state.username,
            password: this.state.password,
          });
        }
      );
    } else {
    }
  };
  static getDerivedStateFromProps(nextProps) {
    const { auth, auth_error } = nextProps.user;
    if (auth && auth.id) {
      Helpers.updateUser(auth);
      return {
        user: auth,
      };
    }
    if (auth_error && auth_error.non_field_errors) {
      return {
        auth_error: auth_error.non_field_errors.join(''),
      };
    }
    return null;
  }
  render() {
    if (this.state.user && this.state.user.id) {
      return <Redirect to={`/`} />;
    }
    return (
      <React.Fragment>
        <div className="login_register_wrap section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-md-10">
                <div className="login_wrap">
                  {this.state.ajaxLoading && (
                    <div className="UserAuthOverlay"></div>
                  )}
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3>Login</h3>
                    </div>
                    <form
                      method="post"
                      noValidate={true}
                      onSubmit={this.submit}
                    >
                      <div className="form-group">
                        <input
                          id="username"
                          type="text"
                          required={true}
                          className="form-control"
                          name="username"
                          placeholder="Your Email"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.username}
                          onClick={this.onInputClick}
                          data-type="email"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="password"
                          type="password"
                          name="password"
                          placeholder="Password"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.password}
                          required={true}
                          data-type="password"
                        />
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          {/* <div className="custome-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checkbox"
                              id="exampleCheckbox1"
                              value=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheckbox1"
                            >
                              <span>Remember me</span>
                            </label>
                          </div> */}
                        </div>
                        <Link to="/login">Forgot password?</Link>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-fill-out btn-block"
                          name="login"
                        >
                          Log in
                        </button>
                        {this.state.auth_error && (
                          <div className="AuthError">
                            {this.state.auth_error}
                          </div>
                        )}
                      </div>
                    </form>
                    <div className="different_login">
                      <span> or</span>
                    </div>
                    {/* <ul className="btn-login list_none text-center">
                            <li><a href="#" className="btn btn-facebook"><i className="ion-social-facebook"></i>Facebook</a></li>
                            <li><a href="#" className="btn btn-google"><i className="ion-social-googleplus"></i>Google</a></li>
                        </ul> */}
                    <div className="form-note text-center">
                      Don't Have an Account?{' '}
                      <Link to="/partner-with-us">Create account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Login.propTypes = {};
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  authenticate: (data) => {
    dispatch(userActions.login(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
