import PropTypes from 'prop-types';
import React from 'react';
import { profileActions } from '../../../actions/profile.action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AddressBlock } from '../../static/address/Address';
class MyAccountAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.getAddress();
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-6">
            <div className="card mb-3 mb-lg-0">
              <div className="card-header">
                <h3>Billing Address</h3>
              </div>
              <div className="card-body">
                {this.props.profile.address &&
                  this.props.profile.address.results &&
                  this.props.profile.address.results.length > 0 && (
                    <AddressBlock
                      data={this.props.profile.address.results[0]}
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h3>Shipping Address</h3>
              </div>
              <div className="card-body">
                {this.props.profile.address &&
                  this.props.profile.address.results &&
                  this.props.profile.address.results.length > 1 && (
                    <AddressBlock
                      data={this.props.profile.address.results[1]}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
MyAccountAddress.propTypes = {};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getAddress: () => {
    dispatch(profileActions.getAddress());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddress);
