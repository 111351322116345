import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Helpers from '../../../util/helpers';
import { productActions } from '../../../actions/product.action';
import config from '../../../config/config';
import { ConfirmationPopup } from '../../static/confirm-popup/ConfirmationPopup';
class GetQuote extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '',
      phone: '',
      message: '',
      ajaxLoading: !1,
      submitted: false,
    };
  }
  update = (event) => {
    let name = event.target.getAttribute('name') || null;
    if (!name) {
      return;
    }
    let allow = event.target.getAttribute('data-restrict');
    if (allow) {
      if (allow === 'number') {
        if (!Helpers.isNuber(event.target.value || ''.trim())) {
          return;
        }
      }
    }
    this.setState({
      [name]: event.target.value || '',
    });
  };
  onFocusIn = (event) => {
    Helpers.focusIn(event);
  };
  onFocusOut = (event) => {
    Helpers.focusOut(event);
  };
  onInputClick = (event) => {
    Helpers.onClick(event);
  };
  submit = (event) => {
    event.preventDefault();
    if (Helpers.validateFrom(event.target)) {
      this.setState(
        {
          ajaxLoading: true,
        },
        () => {
          this.props.getQuote({
            name: this.state.name,
            mobile: `+91${this.state.phone}`,
            message: this.state.message,
            product: this.props.product.id,
          });
        }
      );
    } else {
    }
  };
  static getDerivedStateFromProps(nextProps) {
    const { auth, auth_error } = nextProps.user;
    if (auth && auth.id) {
      Helpers.updateUser(auth);
      return {
        user: auth,
      };
    }
    if (auth_error && auth_error.non_field_errors) {
      return {
        auth_error: auth_error.non_field_errors.join(''),
      };
    }
    return null;
  }
  close = () => {
    this.props.closeQuote();
  };
  componentDidUpdate(prevProps) {
    if (this.state.submitted) {
      return;
    }
    if (
      this.props.products &&
      this.props.products.quote_request &&
      this.props.products.quote_request.id
    ) {
      this.setState({
        ajaxLoading: false,
        submitted: true,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.submitted && <ConfirmationPopup onClose={this.close} />}
        {!this.state.submitted && (
          <div className={`modal-content-medium`}>
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.close}
                style={{
                  position: 'relative',
                  zIndex: 500,
                }}
              >
                <span aria-hidden="true">
                  <i className="ion-ios-close-empty"></i>
                </span>
              </button>
              <div className="position-relative">
                {this.state.ajaxLoading && <div className="OrderOverlay"></div>}
                <div className="row no-gutters">
                  <div className="col-sm-5 ">
                    {this.props.product && this.props.product.id && (
                      <div
                        className="position-relative"
                        style={{
                          minHeight: 200,
                        }}
                      >
                        <div className="ProductNamePopup">
                          {this.props.product.title}
                        </div>
                        <img
                          src={this.props.product.images[0].web_desktop}
                          title={this.props.product.title}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-sm-7">
                    <div className="popup_content popup_content_left_aligned">
                      <div className="popup-text">
                        <div className="heading_s1">
                          <h4>We will arrange you a call back </h4>
                        </div>
                      </div>
                      <form
                        method="post"
                        noValidate={true}
                        onSubmit={this.submit}
                      >
                        <div className="form-group">
                          <input
                            id="name"
                            type="text"
                            required={true}
                            className="form-control"
                            name="name"
                            placeholder="Your name"
                            onBlur={this.onFocusOut}
                            onFocus={this.onFocusIn}
                            onChange={this.update}
                            value={this.state.name}
                            onClick={this.onInputClick}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            id="phone"
                            type="text"
                            required={true}
                            className="form-control"
                            name="phone"
                            placeholder="Phone Number"
                            onBlur={this.onFocusOut}
                            onFocus={this.onFocusIn}
                            onChange={this.update}
                            value={this.state.phone}
                            onClick={this.onInputClick}
                            data-type="phone"
                            data-restrict="number"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            id="message"
                            type="text"
                            required={true}
                            className="form-control"
                            name="message"
                            placeholder="Message"
                            onBlur={this.onFocusOut}
                            onFocus={this.onFocusIn}
                            onChange={this.update}
                            value={this.state.message}
                            onClick={this.onInputClick}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-fill-line btn-block text-uppercase rounded-0"
                            title="Subscribe"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>

                      <div className="form-group text-center">
                        For more help
                        <div className="">
                          <a href={`tel:${config.meta.phone}`}>
                            {config.meta.phone}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
GetQuote.propTypes = {};
const mapStateToProps = (state) => ({
  user: state.user,
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getQuote: (data) => {
    dispatch(productActions.getQuote(data));
  },
  closeQuote: () => {
    dispatch(productActions.quotePopup({ id: 0 }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GetQuote);
