import { ShopConfig } from '../config/shop';
import Validations from './validations';
import $ from 'jqlite';
const ERROR_CLASS = 'HasError';
const MOBILE_MENU = 991;
class Helpers {
  constructor() {
    this.animationFrame();
    this.user = this.initUser();
  }
  initUser() {
    var user = window.__u;
    if (!user) {
      return !1;
    }
    var obj = Object.assign({}, user);
    if (!obj.user) {
      return !1;
    }
    return obj.user;
  }

  updateUser(data) {
    this.user = data;
  }
  getCurrentUser() {
    return this.user;
  }

  serialize = function (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };

  praseQuery(excludeDefaults = false, onlyUrlParams = false) {
    const params = new URLSearchParams(window.location.search),
      query = {};
    for (const param of params) {
      query[param[0]] = param[1];
    }
    if (onlyUrlParams) {
      return query;
    }
    let merged = !excludeDefaults
      ? Object.assign(this.getInitialFilter(), query)
      : query;
    if (merged.filter) {
      let filter = merged.filter.split('::');
      delete merged['filter'];
      filter.map((item) => {
        let split = item.split(':');
        merged[split[0]] = split[1];
      });
    }
    return merged;
  }
  isMobileMenu() {
    return window.innerWidth <= MOBILE_MENU;
  }
  getInitialFilter() {
    return {
      page: 1,
      page_size: ShopConfig.results_per_page[0],
      sort: Object.keys(ShopConfig.sorting_options)[0],
      q: '',
    };
  }
  animationFrame() {
    window.requestAnimFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();
  }
  scrollToY(to, duration = 400) {
    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    const easeInOutQuad = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    return new Promise((resolve, reject) => {
      const element = document.scrollingElement;

      if (typeof to === 'string') {
        to = document.querySelector(to) || reject();
      }
      if (typeof to !== 'number') {
        to = to.getBoundingClientRect().top + element.scrollTop;
      }

      let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      const animateScroll = function () {
        currentTime += increment;
        let val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        } else {
          resolve();
        }
      };
      animateScroll();
    });
  }
  staticQueryChanged(obj) {
    let staticParams = this.getStaticParams(),
      initial = this.getInitialFilter();
    for (let key in staticParams) {
      let param = staticParams[key];
      if (obj.hasOwnProperty(param) && obj[param] != initial[param]) {
        return !0;
      }
    }
    return !1;
  }
  getStaticParams() {
    return Object.keys(this.getInitialFilter());
  }
  addMessage(target, validation) {
    let id = target.getAttribute('id');
    if (!id) {
      id = `form_control_${new Date().getTime()}`;
      target.setAttribute('id', id);
    }
    let existing = document.getElementById(`validation_${id}`);
    if (existing) {
      existing.innerText = validation.message;
      return !0;
    }
    let ele = document.createElement('div');
    ele.setAttribute('class', 'ErrorValidation');
    ele.setAttribute('aria-owns', id);
    ele.setAttribute('id', `validation_${id}`);
    ele.innerText = validation.message;
    target.parentNode.insertBefore(ele, target.nextSibling);
  }
  validate(target) {
    let type = target.getAttribute('data-type'),
      value = (target.value || '').trim(),
      required = target.required;
    if (required && !type) {
      if (!value) {
        this.addMessage(target, {
          status: !1,
          message: 'This field is required',
        });
        target.classList.add(ERROR_CLASS);
        return !1;
      } else {
        this.removeMessage(target);
        return !0;
      }
    }

    if (typeof Validations[type] !== 'function') {
      throw `Validation hook for ${type} is not defined.`;
    }
    let validation = Validations[type](value);
    if (!validation.status) {
      target.classList.add(ERROR_CLASS);
      this.addMessage(target, validation);
      return !1;
    } else {
      this.removeMessage(target);
      return !0;
    }
  }
  isNuber(val) {
    const re = /^[0-9\b]+$/;
    return re.test(val);
  }
  removeMessage(target) {
    let id = target.getAttribute('id'),
      validation = document.getElementById(`validation_${id}`);
    if (validation) {
      validation.remove();
    }
  }
  focusOut(event) {
    let target = event.target;
    this.validate(target);
  }
  focusIn(event) {
    let target = event.target;
    target.classList.remove(ERROR_CLASS);
  }
  parseCount(value) {
    let val = parseInt(value);
    if (val < -1 || val > ShopConfig.MAX_TO_CART) {
      return !1;
    }
    return val;
  }
  onClick(event) {
    let target = event.target;
    target.classList.remove('KeepFocus');
  }
  toast(data) {
    return {
      ...data,
      id: new Date().getTime(),
    };
  }
  validateFrom(form) {
    let inputs = form.querySelectorAll('input'),
      length = inputs.length,
      isValid = !0;
    for (let i = 0; i < length; i++) {
      let ele = inputs[i];
      let status = this.validate(ele);
      if (!status) {
        if (isValid) {
          ele.focus();
          ele.classList.add('KeepFocus');
        }
        isValid = !1;
      }
    }
    return isValid;
  }
  getOfferUrl(url) {
    var split = url.split('/');
    return split[split.length - 2];
  }
  forseSignupDisabled() {
    return localStorage.getItem('frc_sgn') === '1';
  }
  stripHtml(htmlString = '') {
    var temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = htmlString;
    return (
      temporalDivElement.textContent ||
      temporalDivElement.innerText ||
      ''
    ).trim();
  }
  appClick = (event) => {
    if (this.active) {
      if (
        this.active.getAttribute('id') === event.target.getAttribute('id') ||
        this.active.contains(event.target)
      ) {
        return;
      }
      this.active.classList.remove('show');
      this.active = null;
    }
  };
  isDescendant = (el, parentId) => {
    let isChild = false;

    if (el.id === parentId) {
      isChild = true;
    }

    while ((el = el.parentNode)) {
      if (el.id == parentId) {
        isChild = true;
      }
    }

    return isChild;
  };
  closeOpenItem = ()=>{
    this.active && this.active.classList.remove('show') ; 
    this.active = null ;
  }

  toggler = (event) => {
    if( this.active ){
      return ;
    }
    let currentTarget = event.currentTarget,
      target = currentTarget.getAttribute('data-target'),
      type = currentTarget.getAttribute('data-toggle');
    if (!target) {
      return;
    }
    let ele = document.getElementById(target.slice(1));
    if (type === 'collapse') {
      setTimeout(() => {
        if (ele.classList.contains('show')) {
          this.active = null;
        } else {
          this.active = ele;
        }
        ele.classList.toggle('show');
      }, 10);
    }
  };
}
export default new Helpers();
