import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
export class ForceSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClose = () => {
    if (this.props.onClose && 'function' === typeof this.props.onClose) {
      if (this.state.neverAsk) {
        localStorage.setItem('frc_sgn', 1);
      }
      this.props.onClose();
    }
  }
  partnerWithUs = ()=>{
    this.props.onClose(true);
  }
  check = (event) => {
    this.setState({
      neverAsk: event.target.checked ? !0 : !1,
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="modal-content-small">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.onClose}
            >
              <span aria-hidden="true">
                <i className="ion-ios-close-empty"></i>
              </span>
            </button>
            <div className="row no-gutters">
              <div className="col-sm-12">
                <div className="popup_content">
                  <div className="popup-text">
                    <div className="heading_s4">
                      <h4>Partner with us and Get 25% Discount!</h4>
                    </div>
                    <p>
                      Partner with us to receive updates about new products.
                    </p>
                  </div>
                  <form method="post">
                    <div className="form-group">
                      <button
                        className="btn btn-fill-line btn-block text-uppercase rounded-0"
                        title="Subscribe"
                        type="submit"
                        onClick={this.partnerWithUs}
                      >
                        Partner With us
                      </button>
                    </div>
                  </form>
                  <div className="chek-form">
                    <div className="custome-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="exampleCheckbox3"
                        onChange={this.check}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox3"
                      >
                        <span>Don't show this popup again!</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ForceSignup.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFilterOptions: (id) => {
    dispatch(productActions.getFilter(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForceSignup);
