class Validations {
  constructor() {}
  email(email) {
    if (!email) {
      return {
        status: !1,
        message: 'Please enter your email address',
      };
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      return {
        status: true,
      };
    }
    return {
      status: !1,
      message: 'Invalid email address',
    };
  }
  password(password) {
    if (!password) {
      return {
        status: !1,
        message: 'Please enter your password',
      };
    }
    return {
      status: !0,
    };
  }
  gst(gst) {
    if (!gst) {
      return {
        status: !1,
        message: 'GST number is required',
      };
    }
    if (1) {
      return {
        status: !0,
      };
    } else {
      return {
        status: !1,
        message: 'Please enter a valid GST number',
      };
    }
  }
  pin(pincode) {
    var regex = /^\d{6}$/;
    if (!pincode) {
      return {
        status: !1,
        message: 'Pincode is required',
      };
    }
    if (!regex.test(pincode)) {
      return {
        status: !1,
        message: 'Pin code should be 6 digits',
      };
    } else {
      return {
        status: !0,
      };
    }
  }
  phone(phone) {
    if (!phone) {
      return {
        status: !1,
        message: 'Phone number is required',
      };
    }
    if (!/^\d{10}$/.test(phone)) {
      return {
        status: !1,
        message: 'Invalid phone number',
      };
    }
    return {
      status: !0,
    };
  }
  gst(gst) {
    if (!gst) {
      return {
        status: !1,
        message: 'GST number is required',
      };
    }
    if (this._gst(gst)) {
      return {
        status: !0,
      };
    } else {
      return {
        status: !1,
        message: 'Invalid GST number',
      };
    }
  }
  _gst(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g) , p ;
    if(regTest){
       let a=65,b=55,c=36;
       return Array['from'](g).reduce((i,j,k,g)=>{ 
          p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
          return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
       },0); 
   }
   return regTest
  }
}
export default new Validations();
