import PropTypes from 'prop-types';
import React from 'react';
import { productActions } from '../../../actions/product.action';
import { connect } from 'react-redux';
import Banner from '../../static/banner/Banner';
import ProductSection from '../../static/product-section/ProductSection';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      products: !1,
      sections: [
        {
          key: 'new_arrivals',
          all: '/products/all/?page=1&page_size=12&sort=newest',
          title: 'New Arrivals',
          hasSidebar : false
        },
        { key : 'categories' , all : `/products/all` ,title:'Bowse by category',hasSidebar : false , type : 'category' , settings :{
          slidesToShow: 6,
          slidesToScroll: 6,
          swipe : false ,
          draggable : false ,
          infinite : true ,
          autoplaySpeed: 8000,
          speed : 1000 ,
          autoplay : true
        }} ,
        { key: 'premium_products', all: null, title: 'Premium Products', hasSidebar : false },
        { key: 'most_selling', all: null, title: 'Most Selling' , hasSidebar : false },
        { key: 'money_savor', all: null, title: 'Money Savor' , hasSidebar : false },
      ],
    };
  }
  componentDidMount() {
    this.props.getCategories();
    this.props.getOffers();
    this.props.getProductCategory('all');
  }
  static getDerivedStateFromProps(nextProps) {
    const { products } = nextProps;
    if(products && products.categories ){
        return {
          categories: products.categories,
        }
    }
    if (products && products.offers) {
      return {
        offers: products.offers,
      };
    }
    if (
      products &&
      products.products &&
      products.products.res &&
      Object.keys(products.products.res).length
    ) {
      return {
        products: products.products.res,
      };
    }
    return null;
  }
  render() {
    return (
      <React.Fragment>
        <Banner offers={this.state.offers} categories={this.state.categories} />
        <div className="section small_pt">
          <div className="custom-container">
            {this.state.products && this.state.categories &&
              this.state.sections &&
              this.state.sections.map((item, index) => {
                if (!this.state.products.hasOwnProperty(item.key) && item.type!=='category') {
                  return <React.Fragment key={`HomePageSection_${index}`}></React.Fragment>;
                }
                let data = this.state.products[item.key];
                return (
                  <div className="row py-2" key={`HomePageSection_${index}`}>
                    {item.hasSidebar &&(<div className="col-xl-3 d-none d-xl-block">Offers</div>)}
                    <div className={item.hasSidebar ? 'col-xl-9' : 'col-xl-12'}>
                      <ProductSection
                        key={`Section_${index}`}
                        tittle={item.title}
                        viewAll={item.all}
                        name={item.key}
                        data={item.type == 'category'  ? this.state.categories : data}
                        type={item.type}
                        settings={item.settings||{}}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Home.propTypes = {};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getOffers: () => {
    dispatch(productActions.getOffers());
  },
  getProductCategory: (name) => {
    dispatch(productActions.products(name));
  },
  getCategories: () => {
    dispatch(productActions.getCategories());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
