import React from 'react';
import { Price } from '../price/Price';
import { Link } from 'react-router-dom';
import Helpers from '../../../util/helpers';
const defaultImage = require('../../../images/assets/placeholder.jpg');
const IMG_ERR = () => {
  event.target.src = defaultImage;
};
const ON_LOAD = () => {
  let target = document.querySelectorAll(
    `[id='${event.target.getAttribute('aria-controls')}']`
  );
  //  target.style.display='none';
  if (target) {
    for (let i = 0; i < target.length; i++) {
      target[i].style.display = 'none';
    }
  }
  event.target.style.display = 'block';
};

const NOOP = () => {};
export const Product = (props) => {
  return (
    <div className={`item ${props.type}`} data-product={props.data.id}>
      {props.inQue && <div className="ProductAjaxCart"></div>}
      <div className="product_wrap">
        <div className="product_img">
          <Link to={`/product/${props.data.slug}/${props.data.id}`}>
            <img
              src={defaultImage}
              id={`product_placeholder_${props.data.id}`}
            />
            <img
              src={props.data.primary_image.web}
              style={{
                display: 'none',
              }}
              alt={props.data.title}
              aria-controls={`product_placeholder_${props.data.id}`}
              onError={IMG_ERR}
              onLoad={ON_LOAD}
            />
          </Link>
          <div className="product_action_box">
            <ul className="list_none pr_action_btn">
              {Helpers.getCurrentUser() && (
                <li className="add-to-cart">
                  <a
                    onClick={(event) => {
                      props.action(1, props.data.url);
                    }}
                  >
                    <i className="icon-basket-loaded"></i> Add To Cart
                  </a>
                </li>
              )}
              <li>
                <a
                  href={`/?ajax-quick-view=${props.data.id}`}
                  onClick={props.onClick || NOOP}
                  data-product={props.data.id}
                  className="popup-ajax"
                >
                  <i className="icon-magnifier-add"></i>
                </a>
              </li>
              <li>
                <a
                  onClick={(event) => {
                    event.preventDefault();
                    props.addToWishlist &&
                      typeof props.addToWishlist === 'function' &&
                      props.addToWishlist(props.data.id);
                  }}
                >
                  <i className="icon-heart"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="product_info">
          <h6 className="product_title">
            <Link to={`/product/${props.data.slug}/${props.data.id}`}>
              {props.data.title}
            </Link>
          </h6>
          <Price price={props.price} />
          {1 > 10 && (
            <div className="rating_wrap">
              <div className="rating">
                <div className="product_rate"></div>
              </div>
              <span className="rating_num">
                ({props.data.num_approved_reviews})
              </span>
            </div>
          )}
          <div className="pr_desc">
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};
