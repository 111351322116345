import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProductDetail from '../product-main/ProductDetail';
import { history } from '../../../store/configureStore';
export class AjaxQuickView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  close = () => {
    typeof this.props.onClose === 'function' && this.props.onClose();
  };
  render() {
    return (
      <div className="mfp-container mfp-ajax-holder mfp-s-ready">
        <div className="mfp-content">
          <div className="ajax_quick_view">
            <ProductDetail
              product={this.props.product}
              modal={true}
              onClick={this.close}
              modal={true}
            />
            <button
              title="Close (Esc)"
              onClick={this.close}
              type="button"
              className="ReactModalCloseButton"
            >
              ×
            </button>
          </div>
        </div>
      </div>
    );
  }
}
AjaxQuickView.propTypes = {
  product: PropTypes.number,
};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AjaxQuickView);
