import http from '../util/http';
export const userService = {
  sendOtp(data) {
    return http.post('auth/v1/send-otp/', data);
  },
  verifyOtp(data) {
    return http.post('auth/v1/verify-otp/', data);
  },
  register(data) {
    return http.post('auth/v1/complete-registration/', data);
  },
  login(data) {
    return http.post('rest-auth/login/', data);
  },
  logout() {
    return http.post('rest-auth/logout/');
  },
  getAddress() {
    return http.get('useraddresses/');
  },
  register(data) {
    return http.post('auth/partner-with-us/',data);
  },
};
