import Helpers from '../util/helpers';
const user = Helpers.getCurrentUser();
export default (state = { auth: user }, action) => {
  switch (action.type) {
    case 'OTP':
      return {
        ...state,
        otp: action.payload,
      };
    case 'VERIFY_OTP':
      return {
        ...state,
        otp: action.payload,
      };
    case 'USER_ADDRESS':
      return {
        ...state,
        user_address: action.payload,
      };
    case 'USER_REGISTER':
      return {
        ...state,
        user_register: {...action.payload , attempt : ( new Date()).getTime() },
      };
    case 'LOGGED_OUT':
      return {
        ...state,
        auth: !1,
      };
    case 'TOAST_NOTIFY':
      return {
        ...state,
        toast: action.payload,
      };
    case 'AUTH_LOGIN':
      var obj = {
          login : 1
      };
      if (action.payload.user) {
        obj['auth'] = action.payload.user;
        obj['auth_error'] = null;
      } else {
        obj['auth'] = !1;
        obj['auth_error'] = action.payload;
      }
      return {
        // ...state,
        ...obj,
      };
    default:
      return state;
  }
};
