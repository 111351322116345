import { userService } from '../services/user.service';
import Helpers from '../util/helpers';
export const userActions = {
  login: (data) => (dispatch) => {
    userService
      .login(data)
      .then((res) => {
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'AUTH_LOGIN',
          payload: res,
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  register: (data) => (dispatch) => {
    let status;
    userService
      .register(data)
      .then((res) => {
        status = res.status;
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'USER_REGISTER',
          payload: { status: status , res: res },
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  getAddress: () => (dispatch) => {
    userService
      .getAddress()
      .then((res) => {
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'USER_ADDRESS',
          payload: res.results ? res.results[0] : null,
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  logout: () => (dispatch) => {
    userService
      .logout()
      .then((res) => {
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem('user');
        Helpers.updateUser(!1);
        dispatch({
          type: 'LOGGED_OUT',
          payload: res.user,
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  toast: (data) => (dispatch) => {
    dispatch({
      type: 'TOAST_NOTIFY',
      payload: Helpers.toast(data),
    });
  },
  action: (actionName, params) => (dispatch) => {
    let actionMap = {
      OTP: 'sendOtp',
      VERIFY_OTP: 'verifyOtp',
      REGISTER: 'register',
    };

    if (
      actionName &&
      Object.prototype.hasOwnProperty.call(actionMap, actionName)
    ) {
      userService[actionMap[actionName]](params)
        .then((response) => response.json())
        .then((res) => {
          dispatch({
            type: actionName,
            payload: res,
          });
          console.log('After');
        })
        .catch((status) => {
          console.log('Error', status, 'Err');
        });
    }
  },
};
