const config = {
  api: 'https://www.woodncart.com/api/v1/',
  currencyCode: 'INR',
  currency: '₹',
  siteurl : 'https://www.woodncart.com/',
  meta: {
    email: `help@woodncart.com`,
    phone: `+91 9633 888 355`,
    address: '123 Street, Old Trafford, New South London , UK',
    name: `Wood'nCart`,
    year: new Date().getFullYear(),
  },
  razor_Pay: {
    key: 'rzp_test_inQmyWJHeAt70Z',
    currency: 'INR',
    name: 'WoodnCart',
    description: 'Woodncart Checkout',
    image: 'https://www.woodncart.com/logo-dark.png',
    theme: {
      color: '#E1620E',
    },
  },
};
module.exports = config;
