import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CategoryMenu } from '../../static/category-menu/CategoryMenu';
import { Link } from 'react-router-dom';
import MiniCart from '../../static/mini-cart/MiniCart';
import { history } from '../../../store/configureStore';
import { userActions } from '../../../actions/userActions';
import HeaderSecondary from '../header-secondary/HeaderSecondary';
import config from '../../../config/config';
import Helpers from '../../../util/helpers';
import SearchBox from '../../static/saerch/SearchBox';
export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      categories: [],
      category: 'all',
      query: '',
    };
  }

  expandMenu = () => {
    this.setState({
      expanded: !this.state.expanded ? true : false,
    });
  };
  update = (event) => {
    this.setState({
      [event.target.getAttribute('name')]: event.target.value,
    });
  };
  search = (event) => {
    event.preventDefault();
    if (!this.state.query && this.state.category === 'all') {
      return;
    }
    let path = `/products/${this.state.category}/?q=${this.state.query}`;
    if (path === this.state.lastQuery) {
      return;
    }
    this.setState(
      {
        category: 'all',
        query: '',
        lastQuery: path,
      },
      () => {
        history.push(path);
      }
    );
  };
  openSearch = () => {
    this.setState({
      mobileSearch: true,
    });
  };
  closeSearch = () => {
    this.setState({
      mobileSearch: false,
    });
  };
  logout = () => {
    this.props.logout();
  }
  render() {
    return (
      <React.Fragment>
        <div
          className={`search_overlay ${this.state.mobileSearch ? 'open' : ''}`}
        ></div>
        <div className={`search_wrap ${this.state.mobileSearch ? 'open' : ''}`}>
          <span className="close-search" onClick={this.closeSearch}>
            <i className="ion-ios-close-empty"></i>
          </span>
          <form action="/products/all/" method="get">
            <input
              type="text"
              placeholder="Search"
              className="form-control"
              id="search_input"
              name="q"
            />
            <button type="submit" className="search_icon">
              <i className="ion-ios-search-strong"></i>
            </button>
          </form>
        </div>
        {this.props.pathname === '/' && (
          <header ref={(node) => (this.node = node)} className="header_wrap">
            <div className="top-header light_skin bg_dark d-none d-md-block">
              <div className="custom-container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="text-center text-md-right">
                      <ul className="header_list">
                        <li>
                          <Link to={'/user/wishlist/all/'}>
                            <i className="ti-heart"></i>
                            <span>Wishlist</span>
                          </Link>
                        </li>
                        {(!this.props.user || !this.props.user.auth) && (
                          <React.Fragment>
                            <li>
                              <Link to={'/partner-with-us'}>
                                <i className="ti-control-shuffle"></i>
                                <span>Partner with us</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={'/login'}>
                                <i className="ti-user"></i>
                                <span>Login</span>
                              </Link>
                            </li>
                          </React.Fragment>
                        )}
                        {this.props.user && this.props.user.auth && (
                          <React.Fragment>
                            <li>
                              <Link to={'/user/my-account/orders'}>
                                <i className="ti-user"></i>
                                <span>{this.props.user.auth.username}</span>
                              </Link>
                            </li>
                            <li>
                              <a onClick={this.logout}>
                                <i className="ti-power-off"></i>
                                <span>Logout</span>
                              </a>
                            </li>
                          </React.Fragment>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="middle-header dark_skin">
              <div className="custom-container">
                <div className="nav_block">
                  <Link className="navbar-brand" to="/">
                    <img
                      className="logo_light"
                      src={require('../../../images/assets/logo-dark.png')}
                      alt="logo"
                    />
                    <img
                      className="logo_dark"
                      src={require('../../../images/assets/logo-dark.png')}
                      alt="logo"
                    />
                  </Link>
                  <div className="product_search_form">
                    <SearchBox />
                  </div>
                  <ul className="navbar-nav attr-nav align-items-center">
                    {this.props.user && this.props.user.auth && (
                      <li>
                        <Link
                          to={`/user/my-account/orders`}
                          className="nav-link"
                        >
                          {this.props.user.auth.username}{' '}
                          <i className="linearicons-user"></i>
                        </Link>
                      </li>
                    )}
                    <MiniCart />
                  </ul>
                </div>
              </div>
            </div>
            <div className="bottom_header dark_skin main_menu_uppercase border-top border-bottom">
              <div className="custom-container">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-3">
                    <div className="categories_wrap">
                      <button
                        type="button"
                        data-toggle="collapse"
                        data-target="#navCatContent"
                        className="categories_btn"
                        onClick={Helpers.toggler}
                      >
                        <i className="linearicons-menu"></i>
                        <span>All Categories </span>
                      </button>
                      {this.props.pathname === '/' && (
                        <div
                          id="navCatContent"
                          className="nav_cat navbar collapse"
                        >
                          <ul>
                            {this.props.categories &&
                              this.props.categories
                                .slice(0, this.props.limit)
                                .map((item, index) => {
                                  return (
                                    <CategoryMenu
                                      menu={item}
                                      key={`category_menu_${index}`}
                                      index={index}
                                    />
                                  );
                                })}
                            {this.props.categories &&
                              this.props.categories.length >
                                this.props.limit && (
                                <li>
                                  <ul
                                    className="more_slide_open"
                                    style={{
                                      display: this.state.expanded
                                        ? 'block'
                                        : 'none',
                                    }}
                                  >
                                    {this.props.categories
                                      .slice(this.props.limit)
                                      .map((item, index) => {
                                        return (
                                          <CategoryMenu
                                            menu={item}
                                            index={index}
                                            key={`category_more_menu_${index}`}
                                          />
                                        );
                                      })}
                                  </ul>
                                </li>
                              )}
                          </ul>
                          {this.props.categories &&
                            this.props.categories.length > this.props.limit && (
                              <div
                                className={`more_categories ${
                                  this.state.expanded ? 'show' : ''
                                }`}
                                onClick={this.expandMenu}
                              >
                                More Categories
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-6 col-9">
                    <nav className="navbar navbar-expand-lg">
                      <button
                        className="navbar-toggler side_navbar_toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSidetoggle"
                        onClick={Helpers.toggler}
                      >
                        <span className="ion-android-menu"></span>
                      </button>
                      <div className="pr_search_icon">
                        <a
                          onClick={this.openSearch}
                          className="nav-link pr_search_trigger"
                        >
                          <i className="linearicons-magnifier"></i>
                        </a>
                      </div>
                      <div
                        className="collapse navbar-collapse mobile_side_menu"
                        id="navbarSidetoggle"
                      >
                        <ul className="navbar-nav">
                          <li>
                            <Link className="nav-link" to={`/`}>
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link className="nav-link" to={'/products/all/'}>
                              Shop
                            </Link>
                          </li>
                          <li>
                            <a
                              className="nav-link nav_item"
                              href="contact.html"
                            >
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="contact_phone contact_support">
                        <i className="linearicons-phone-wave"></i>
                        <span>
                          <a href={`tel:${config.meta.phone}`}>
                            {config.meta.phone}
                          </a>
                        </span>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}
        {this.props.pathname !== '/' && (
          <HeaderSecondary mobileMenu={this.openSearch} {...this.props} />
        )}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  children: PropTypes.element,
  getCategories: PropTypes.func,
  categories: PropTypes.array,
  limit: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    products: state.products,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(userActions.logout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
