export const ShopConfig = {
  results_per_page: [12, 24],
  sorting_options: {
    relevancy: 'Relevancy',
    rating: 'Rating',
    newest: 'Newest',
    'price-desc': 'Price Desc',
    'price-asc': 'Price Asc',
    'title-asc': 'Title Asc',
    'title-desc': 'Title Description',
  },
  MAX_TO_CART: 10,
  MIN_TO_CART: 1,
};
