import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';
const settings = {
  dots: true,
  slidesToShow: 6,
  slidesToScroll: 6,
  infinite: false,
}
const defaultImage = require('../../../images/assets/placeholder.jpg');
const IMG_ERR = () => {
  event.target.src = defaultImage;
};
export class ProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      initialAction: true,
      images:
        Array.isArray(props.images) && props.images.length ? props.images : !1,
      selected: 0,
    };
  }
  getImage(images, index = 0) {
    return images[index];
  }
  handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - (top + window.scrollY)) / height) * 100;
    this.wrapper.style.backgroundPosition = `${x}% ${y}%`;
  };
  componentDidMount() {
    this.wrapper &&
      this.wrapper.addEventListener('mousemove', this.handleMouseMove);
  }
  componentWillUnmount() {
    this.wrapper &&
      this.wrapper.removeEventListener('mousemove', this.handleMouseMove);
  }
  updateImage = (event) => {
    let target = event.currentTarget,
      index = parseInt(target.getAttribute('data-index'));
    if (isNaN(index) || index < -1 || index === this.state.selected) {
      return;
    }
    this.setState({
      spinner: true,
      selected: parseInt(index),
    });
  };
  onImageLoad = () => {
    this.setState({
      spinner: false,
      initialAction: false,
    });
  };
  render() {
    return (
      <div className="ProductImageWrapper">
        {this.state.images && (
          <figure
            ref={(node) => (this.wrapper = node)}
            className="ProductImageMain"
            onMouseMove={this.handleMouseMove}
            style={{
              backgroundPosition: this.state.backgroundPosition,
              backgroundImage: `url(${
                this.state.images[this.state.selected].web_zoom
              })`,
            }}
          >
            <img
              title={this.props.title}
              className="ProductImageMain"
              src={this.state.images[this.state.selected].mobile}
              onLoad={this.onImageLoad}
              onError={IMG_ERR}
              style={{
                display: !this.state.initialAction ? 'block' : 'none',
              }}
            />
            <img
              style={{
                display:
                  this.state.initialAction
                    ? 'block'
                    : 'none',
              }}
              title={this.props.title}
              className="ProductImageMain PlaceHolderImage"
              src={defaultImage}
            />
            <span
              aria-hidden={this.state.spinner ? 'true' : 'false'}
              className="ProductImageLoading"
            >
              <img
                tittle="No images available"
                className="no-image"
                src={require('../../../images/assets/loading-spinner.gif')}
              />
            </span>
          </figure>
        )}
        {!this.state.images && (
          <figure className="ProductImageMain">
            <img
              tittle="No images available"
              className="no-image"
              src="../images/icon-no-image.svg"
            />
          </figure>
        )}
        <div className="PhotosCount">
          <span>{`${
            this.state.images
              ? `${this.state.images.length} Photos`
              : 'No photos'
          }`}</span>
        </div>
        {this.state.images && (
          <div className="ProductImageThumbs">
            <ul>
              <Slider
                className={
                  'SlickSliderWrapper SlickStyleDefault StickySylePrimaryStatic'
                }
                {...settings}
              >
                {this.state.images &&
                  this.state.images.length &&
                  this.state.images.map((image, index) => {
                    return (
                      <li
                        key={`product_thumb_${index}`}
                        onClick={this.updateImage}
                        data-index={index}
                        aria-selected={
                          index === this.state.selected ? 'true' : 'false'
                        }
                      >
                        <img
                          onError={IMG_ERR}
                          title={this.props.title}
                          src={image.mobile}
                        />
                      </li>
                    );
                  })}
              </Slider>
            </ul>
          </div>
        )}
      </div>
    );
  }
}
ProductImage.propTypes = {
  images : PropTypes.array ,
  title : PropTypes.string
};
const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductImage);
