import http from '../util/http';
import Helpers from '../util/helpers';
export const profileService = {
    getAddress() {
        return http.get('useraddresses/');
    },
    getOrders(params) {
        return http.get(`_orders/?${Helpers.serialize(params)}`);
    }
};
