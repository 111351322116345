import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { Price } from '../price/Price';
import ProductImage from '../prouct-image/ProductImage';
import { TextBlock } from '../text-block/TextBlock';
import { Link } from 'react-router-dom';
import AddToCart from '../add-to-cart/AddToCart';
import config from '../../../config/config';
import { Helmet } from 'react-helmet';
import Helpers from '../../../util/helpers';
import {
  Twitter,
  Facebook,
  Google,
  Youtube,
  Instagram,
} from 'react-social-sharing';
const ShareBox = (props) => {
  return (
    <div className="product-d-block product_share">
      <span>Share:</span>

      <Facebook className="SocialShareButton" link={props.link} />

      <Twitter className="SocialShareButton" link={props.link} />

      <Google className="SocialShareButton" link={props.link} />
    </div>
  );
};
const SortInfo = (props) => {
  return (
    <div className="product_sort_info">
      <ul>
        {props.data &&
          props.data.length > 0 &&
          props.data.map((info, index) => {
            return (
              <li key={`${props.name}_${index}`}>
                {info.icon && <i className={info.icon}></i>}
                {info.text && <span>{info.text}</span>}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      selected: null,
      ajax_refreshing: false,
    };
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { products } = nextProps;
    if (products && products.detail) {
      return {
        product: products.detail,
        id: nextProps.product,
        ajax_refreshing: state.ajax_refreshing
          ? products.detail.id !== state.id
            ? false
            : true
          : false,
      };
    }
    return null;
  }
  componentDidMount() {
    this.getProduct();
  }
  getProduct(id = null) {
    this.props.getProductDetail(id || this.props.product);
  }
  updateProduct = (id) => {
    this.setState(
      {
        ajax_refreshing: true,
      },
      () => {
        this.getProduct(id);
      }
    );
  };
  wishlist = (id) => {
    this.props.addToWishList({
      product_id: id,
    });
  };

  onDetailPageRequest = () => {
    this.props.modal && this.props.onClick && this.props.onClick();
  };
  render() {
    if (!this.state.product) {
      return <></>;
    }
    return (
      <React.Fragment>
        {this.state.product && (
          <Helmet>
            <title>Wood'n Cart | {this.state.product.title}</title>
            <link
              rel="canonical"
              href={`${config.siteurl}/product/${this.state.product.slug}/${this.state.product.id}`}
            />
<meta name="description" content={Helpers.stripHtml(this.state.product.description)} />
<meta property="og:type" content="website" />
<meta property="og:url" content={`${config.siteurl}/product/${this.state.product.slug}/${this.state.product.id}`} />
<meta property="og:title" content={`Wood'n Cart | ${this.state.product.title}`} />
<meta property="og:description" content={Helpers.stripHtml(this.state.product.description)} />
<meta property="og:image" content={this.state.product.images[0].web_desktop} />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={`${config.siteurl}/product/${this.state.product.slug}/${this.state.product.id}`} />
<meta property="twitter:description" content={Helpers.stripHtml(this.state.product.description)} />
<meta property="twitter:title" content={`Wood'n Cart | ${this.state.product.title}`} />
<meta property="twitter:image" content={this.state.product.images[0].web_desktop} />
          </Helmet>
        )}
        <div className="mfp-container mfp-ajax-holder mfp-s-ready">
          {this.state.ajax_refreshing && (
            <div className="mfp-container--ajax-refresh"></div>
          )}
          <div className="mfp-content">
            <div className="ajax_quick_view">
              <div className="row">
                <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
                  <div className="product-image">
                    <ProductImage
                      title={this.state.product.title}
                      images={this.state.product.images || []}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="pr_detail">
                    <div className="product_description">
                      <h4 className="product-d-block product_title">
                        <Link
                          onClick={this.onDetailPageRequest}
                          to={`/product/${this.state.product.slug}/${this.state.product.id}`}
                        >
                          {this.state.product.title}
                        </Link>
                      </h4>
                      <Price
                        count={this.state.selected}
                        price={this.state.product.price}
                      />
                      <div className="product-d-block pr_desc">
                        <TextBlock text={this.state.product.description} />
                      </div>
                      <SortInfo
                        name={'SortInfo'}
                        data={[
                          {
                            icon: 'linearicons-shield-check',
                            text: '1 Year ALJazeera Brand Warranty',
                          },
                          {
                            icon: 'linearicons-sync',
                            text: '30 Day ReturnPolicy',
                          },
                          {
                            icon: 'linearicons-bag-dollar',
                            text: 'Cash on Delivery available',
                          },
                        ]}
                      />
                    </div>
                    <AddToCart
                      key={this.state.product.id}
                      id={this.state.product.id}
                      variants={this.state.product.siblings || {}}
                      onChange={this.updateProduct}
                      url={this.state.product.url}
                      product={this.state.product}
                      addToWishlist={this.wishlist}
                    />

                    <ul className="product-d-block product-meta">
                      {this.state.product.categories && (
                        <li>
                          Category: {this.state.product.categories.join(',')}
                        </li>
                      )}
                      {this.state.product &&
                        this.state.product.attributes &&
                        this.state.product.attributes.map((item, index) => {
                          return (
                            <li
                              aria-label={item.name}
                              key={`ProductAttribute_${index}`}
                            >
                              <span>{item.name} : </span>
                              <span>{item.value}</span>
                            </li>
                          );
                        })}
                    </ul>
                    <ShareBox
                      link={`${config.siteurl}/product/${this.state.product.slug}/${this.state.product.id}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ProductDetail.propTypes = {
  product: PropTypes.number,
};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getProductDetail: (productId) => {
    return dispatch(productActions.getDetails(productId));
  },
  addToWishList: (data) => {
    return dispatch(productActions.addToWishList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
