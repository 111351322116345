import http from '../util/http';
export const productService = {
  getCategories() {
    return http.get('catalogue/c/');
  },
  search( query ){
    return http.get(`catalogue/suggestions/?q=${query}`);
    
  },
  getQuote( data ){
      return http.post(`enquiry/`, data);
  },
  getOffers() {
    return http.get('offers/');
  },
  products() {
    return http.get('web-index/');
  },
  detail(id) {
    return http.get(`catalogue/d/${id}`);
  },
  getWishList(params) {
    return http.get(`_wishlist/`,params);
  },
  addToWishList(params) {
    return http.post(`_wishlist/`,params);
  },
  removeFromWishList(params) {
    return http.patch(`_wishlist/`,params,false);
  },
  addToCart(data) {
    return http.post(`basket/add-product/`, data);
  },
  buyNow(data){
    return http.post(`buy-now/get-basket/`, data);
  },
  checkout(data) {
    return http.post(`checkout/`, data);
  },
  getFilter(id) {
    return http.get(`catalogue/f/${id}`);
  },
  getCart() {
    return http.get(`_basket/`);
  },
  getProducts(data) {
    return http.get(`catalogue/c/${data.category}/${data.query}`);
  },
  getOfferProducts( data ){
    return http.get(`offers/${data.category}/${data.query}`);
  },
  removeFromCart(data){
    return http.delete(data.cartId,{},true)
  }
};
