import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { userActions } from '../../../actions/userActions';
import config from '../../../config/config';
import { Link } from 'react-router-dom';
import Helpers from '../../../util/helpers';
import { PageNotFound } from '../../static/404/404';
import { Price } from '../price/Price';
export class WishList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wishlist: !1,
    };
  }
  componentDidMount() {
    this.props.getWishlist();
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { wish_list } = nextProps.products;
    if (wish_list && wish_list.lines) {
      return {
        wishlist: wish_list,
      };
    }
    return null;
  }
  removeFromWishList = (event) => {
    event.preventDefault();
    var id = event.currentTarget.getAttribute('data-id');
    this.props.removeFromWishList({
      product_id : id 
    })
  };
  addToCart = (event) => {
    event.preventDefault();
    var url = event.currentTarget.getAttribute('data-id');
    url &&
      this.props.addTocart({
        quantity: 1,
        url: url,
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive shop_cart_table">
                  {this.state.cart_refreshing && (
                    <div className="CartUpdateOverlay"></div>
                  )}
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="product-thumbnail">Image</th>
                        <th className="product-name">Product</th>
                        <th className="product-price">Price</th>
                        <th className="product-add-to-cart">Add to Cart</th>
                        <th className="product-remove">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.wishlist &&
                        (!this.state.wishlist.lines ||
                          !this.state.wishlist.lines.length) && (
                          <tr>
                            <td colSpan={6}>
                              <PageNotFound desc={'Your wishlist is empty'} />
                            </td>
                          </tr>
                        )}
                      {this.state.wishlist &&
                        this.state.wishlist.lines &&
                        this.state.wishlist.lines.map((item, index) => {
                          return (
                            <tr
                              key={`cart_item_${item.id}`}
                              data-item-id={item.id}
                            >
                              <td className="product-thumbnail">
                                <img
                                  src={item.product.image.web}
                                  alt="product1"
                                />
                              </td>
                              <td className="product-name" data-title="Product">
                                {item.get_title}
                              </td>
                              <td className="product-price" data-title="Price">
                                <Price price={item.product.price} />
                              </td>
                              <td className="product-add-to-cart">
                                <a
                                  onClick={this.addToCart}
                                  data-id={item.product.url}
                                  className="btn btn-fill-out LinkButton"
                                >
                                  <i className="icon-basket-loaded"></i> Add to
                                  Cart
                                </a>
                              </td>
                              <td
                                className="product-remove"
                                data-title="Remove"
                              >
                                <a
                                  className="RemoveFromWishList"
                                  onClick={this.removeFromWishList}
                                  data-id={item.product.id}
                                >
                                  <i className="ti-close"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WishList.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getWishlist: () => {
    dispatch(productActions.getWishList());
  },
  addTocart: (data) => {
    return dispatch(productActions.addTocart(data));
  },
  removeFromWishList : (data) => {
    return dispatch(productActions.removeFromWishList(data));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(WishList);
