import PropTypes, { nominalTypeHack } from 'prop-types';
import React from 'react';
import { productActions } from '../../../actions/product.action';
import { connect } from 'react-redux';
import { Product } from '../../static/product/Product';
import { Link } from 'react-router-dom';
import { ShopConfig } from '../../../config/shop';
import Filter from '../../static/filter/Filter';
import { history } from '../../../store/configureStore';
import Helpers from '../../../util/helpers';
import Pagination from 'react-js-pagination';
import { PageNotFound } from '../../static/404/404';
const INITIAL_FILTER = Helpers.getInitialFilter();
const WidgetCategories = (props) => {
  return (
    <div className="widget">
      <h5 className="widget_title">Categories</h5>
      <ul className="widget_categories">
        <li>
          <Link
            className={`ShopCategory ${props.active === 'all' ? 'active' : ''}`}
            to={`/products/all/`}
          >
            <span className="categories_name">All</span>
          </Link>
        </li>
        {props.categories &&
          props.categories &&
          props.categories.map((category, index) => {
            return (
              <li key={`ShopCategory-${category.id}_${index}`}>
                <Link
                  to={`/products/${category.slug}/`}
                  className={`ShopCategory ${
                    props.active === category.slug ? 'active' : ''
                  }`}
                >
                  <span className="categories_name">{category.name}</span>
                  <span className="categories_num">
                    ({category.product_count})
                  </span>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      style: 'grid',
      category: this.category(),
      type: this.getType(),
      queryString: '',
      pageUpdating: false,
      hasFilter: this.category() !== 'all',
      filter: this.getFilter(),
      excludeHistoryPush: false,
      activePage: 0,
      refresh_tocken: new Date().getTime(),
      que: [],
    };
  }
  getFilter() {
    let params = Helpers.praseQuery();
    return params;
  }
  category() {
    if (this.props.match && this.props.match.params) {
      let { categoryName } = this.props.match.params;
      if (categoryName) {
        return categoryName;
      }
    }
    return null;
  }
  getType() {
    if (this.props.match && this.props.match.params) {
      let { type } = this.props.match.params;
      if (type) {
        return type;
      }
    }
    return null;
  }
  grid = () => {
    this.setState({
      style: 'grid',
    });
  };
  list = () => {
    this.setState({
      style: 'list',
    });
  };
  componentDidMount() {
    this.fetchProducts();
    this.props.getCategories();
  }
  getParams() {
    let query = `?`,
      obj = Object.assign({}, this.state),
      filter = obj.filter,
      normal = Helpers.getStaticParams();

    if (Object.keys(filter).length > 0) {
      let added = 0;
      Object.keys(filter).map((item, index) => {
        if (normal.indexOf(item) > -1 && filter[item]) {
          query += `${item}=${filter[item]}&`;
          return;
        }
        if ((filter[item] || '').trim()) {
          query += `${added === 0 ? 'filter=' : ''}${
            added > 0 ? '::' : ''
          }${item}:${filter[item]}`;
          added++;
        }
      });
    }
    if (query.lastIndexOf('&') === query.length - 1) {
      query = query.slice(0, -1);
    }
    if (
      !this.state.excludeHistoryPush &&
      (this.state.hasChanges ||
        Helpers.staticQueryChanged(Object.assign({}, this.state.filter)) ||
        Object.keys(filter).length > normal.length)
    ) {
      history.push(
        `/${this.state.type === 'offers' ? 'offers' : 'products'}/${
          obj.category
        }/${query}`
      );
    }
    return {
      category: obj.category,
      query: query,
    };
  }
  componentDidUpdate(prevProps, state) {
    const { match } = prevProps;
    if (match && match.params) {
      let { categoryName } = match.params;
      if (
        categoryName &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.categoryName &&
        this.props.match.params.categoryName !== categoryName
      ) {
        this.setState(
          {
            filter: { ...INITIAL_FILTER },
          },
          () => {
            this.fetchProducts();
          }
        );
      }
    }
    if (prevProps.location.search !== this.state.queryString) {
      this.setState(
        {
          filter: this.getFilter(),
          excludeHistoryPush: true,
          refresh_tocken: new Date().getTime(),
        },
        this.fetchProducts
      );
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { products, match } = nextProps;
    if (match && match.params) {
      let { categoryName } = match.params;
      if (categoryName && categoryName !== state.category) {
        return {
          category: categoryName,
          hasFilter: categoryName !== 'all',
          filter: INITIAL_FILTER,
        };
      }
    }
    if (products && products.categories) {
      return {
        categories: products.categories,
      };
    }
    if (
      products &&
      products.shop_products &&
      products.shop_products.uid !== state.products.uid
    ) {
      return {
        products: products.shop_products,
        pageUpdating: false,
        excludeHistoryPush: false,
      };
    }
    const { cart_updated, item } = nextProps.products;
    if (cart_updated && item && item.target) {
      let que = state.que;
      que.splice(que.indexOf(item.target), 1);
      return { que };
    }
    if (nextProps.location) {
      return {
        queryString: nextProps.location.search || '',
      };
    }

    return null;
  }
  ajaxQucikView = (event) => {
    event.preventDefault();
    let target = event.currentTarget,
      id = parseInt(target.getAttribute('data-product'));
    id && !isNaN(id) && this.props.ajaxQucikView(id);
    return (event.returnValue = !1);
  };
  filterList = () => {
    this.fetchProducts();
  };
  fetchProducts = () => {
    this.setState(
      {
        pageUpdating: true,
      },
      () => {
        this.state.type === 'products'
          ? this.props.getProducts(this.getParams())
          : this.state.type === 'offers'
          ? this.props.getOfferProducts(this.getParams())
          : null;
      }
    );
  };
  onChange = (filter) => {
    this.setState(
      {
        filter: Object.assign({}, this.state.filter, { page: 1 }, filter),
      },
      this.filterList
    );
  };
  update = (event) => {
    this.updateFilterObject({
      [event.target.getAttribute('name')]: event.target.value,
    });
  };
  updateFilterObject = (name, value) => {
    let object = {};
    if (typeof name == 'object') {
      object = name;
    } else {
      object = {
        [name]: value,
      };
    }
    this.setState(
      {
        filter: Object.assign({}, this.state.filter, object),
        hasChanges: true,
      },
      this.filterList
    );
  };
  pagination = (page) => {
    this.updateFilterObject('page', page);
  };
  addToCart = (count, url) => {
    this.setState(
      {
        que:
          this.state.que.indexOf(url) === -1
            ? [...this.state.que, url]
            : this.state.que,
      },
      () => {
        this.props.addToCart({
          quantity: count,
          url: url,
        });
      }
    );
  }
  wishlist = ( id )=>{
    this.props.addToWishList({
      product_id : id
    });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.type === 'offers' && (
          <div className="breadcrumb_section bg_gray page-title-mini">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="page-title">
                    <h1>Offers</h1>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        )}
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="row align-items-center mb-4 pb-1">
                  <div className="col-12">
                    <div className="product_header">
                      <div className="product_header_left">
                        <div className="custom_select">
                          <select
                            name="sort"
                            onChange={this.update}
                            className="form-control form-control-sm"
                            value={this.state.filter.sort}
                          >
                            <option value="relevancy">Sort by : Default</option>
                            {ShopConfig.sorting_options &&
                              Object.keys(ShopConfig.sorting_options).map(
                                (option, index) => {
                                  return (
                                    <option
                                      key={`SortBy_${index}`}
                                      value={option}
                                    >
                                      Sort by :{' '}
                                      {ShopConfig.sorting_options[option]}
                                    </option>
                                  );
                                }
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="product_header_right">
                     
                        <div className="custom_select">
                          <select
                            name="page_size"
                            onChange={this.update}
                            className="form-control form-control-sm"
                            value={this.state.filter.page_size}
                          >
                            <option value="">Showing</option>
                            {ShopConfig &&
                              ShopConfig.results_per_page &&
                              ShopConfig.results_per_page.map((item, index) => {
                                return (
                                  <option
                                    key={`ResultsPerPage_${index}`}
                                    value={item}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`row shop_container_custom ${this.state.style}`}
                >
                  {this.state.pageUpdating && (
                    <div className="ProductOverlay"></div>
                  )}
                  {!this.state.products ||
                    !this.state.products.results ||
                    (!this.state.products.results.length && <PageNotFound />)}
                  {this.state.products &&
                    this.state.products.results &&
                    this.state.products.results.map((product, index) => {
                      return (
                        <div
                          className="col-md-4 col-6"
                          key={`shop_page_${product.id}_${index}`}
                        >
                          <Product
                            index={index}
                            onClick={() => {}}
                            data={product}
                            onClick={this.ajaxQucikView}
                            price={product.price}
                            action={this.addToCart}
                            addToWishlist={this.wishlist}
                            inQue={this.state.que.indexOf(product.url) > -1}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.state.products &&
                      this.state.products.count >
                        this.state.filter.page_size && (
                        <Pagination
                          pageRangeDisplayed={3}
                          itemClass={'page-item'}
                          linkClass={'page-link'}
                          activeClass={'active'}
                          activeLinkClass={'active-page'}
                          innerClass={
                            'pagination mt-3 justify-content-center pagination_style1'
                          }
                          activePage={parseInt(this.state.filter.page)}
                          itemsCountPerPage={this.state.page_size}
                          totalItemsCount={this.state.products.count}
                          onChange={this.pagination}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 order-lg-first mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div className="sidebar">
                  {this.state.products &&
                    this.state.products.results &&
                    this.state.hasFilter && (
                      <Filter
                        initial={this.state.filter}
                        key={`ShopFilter_Options_${
                          this.state.products &&
                          this.state.products.product_class &&
                          this.state.products.product_class.id
                            ? this.state.products.product_class.id
                            : 0
                        }`}
                        tocken={this.state.refresh_tocken}
                        onChange={this.onChange}
                        filter={
                          this.state.products &&
                          this.state.products.product_class
                            ? this.state.products.product_class
                            : {}
                        }
                      />
                    )}
                  <WidgetCategories
                    active={this.state.category}
                    categories={this.state.categories}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Shop.propTypes = {};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getProducts: (data) => {
    dispatch(productActions.getProducts(data));
  },
  ajaxQucikView: (id) => {
    dispatch(productActions.ajaxQuickView(id));
  },
  getOfferProducts: (data) => {
    dispatch(productActions.getOfferProducts(data));
  },
  getCategories: () => {
    dispatch(productActions.getCategories());
  },
  addToCart: (data) => {
    return dispatch(productActions.addTocart(data));
  },
  addToWishList : (data) => {
    return dispatch(productActions.addToWishList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
