import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import { Product } from '../product/Product';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
const settings = {
  slidesToShow: 6,
  slidesToScroll:6,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};
const placeHolder = require('../../../images/assets/image_web_placeholder.png');
const CategoryBox = (props) => {
  return (
    <div className="col-12">
      <div
        className="sale-banner CategoryBannerItem"
        style={{
          backgroundImage: `url(${props.data.thumbnail_web_listing})`,
        }}
      >
        <Link
          className="hover_effect1 CategoryLink"
          to={`/products/${props.data.slug}`}
        >
          <h3 className="CategorySliderTitle">{props.data.name}</h3>
          <img src={placeHolder} alt={props.data.name} />
        </Link>
      </div>
    </div>
  );
};
export class ProductSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.name,
      products: this.props.data || [],
      que: [],
    };
  }
  static getDerivedStateFromProps(nextProps, state) {
    const { products, cart_updated, item } = nextProps.products;
    if (products && products.key === state.key) {
      return {
        key: products.key,
        products:
          products.res && products.res.hasOwnProperty(state.key)
            ? products.res[state.key]
            : [],
      };
    }
    if (cart_updated && item && item.target) {
      let que = state.que;
      que.splice(que.indexOf(item.target), 1);
      return { que };
    }
    return null;
  }
  componentDidMount() {
    if (!this.props.data && this.props.type !== 'category') {
      let def = (this.props.name || 'trending').toLowerCase();
      this.props.getProductCategory(def);
    }
  }
  onClick = (event) => {
    event.preventDefault();
    let target = event.currentTarget,
      id = parseInt(target.getAttribute('data-product'));
    id && !isNaN(id) && this.props.ajaxQuickView(id);
    return (event.returnValue = !1);
  };
  addToCart = (count, url) => {
    this.setState(
      {
        que:
          this.state.que.indexOf(url) === -1
            ? [...this.state.que, url]
            : this.state.que,
      },
      () => {
        this.props.addToCart({
          quantity: count,
          url: url,
        });
      }
    );
  }
  wishlist = ( id )=>{
    this.props.addToWishList({
      product_id : id
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="heading_tab_header">
              <div className="heading_s2">
                <h4>{this.props.tittle}</h4>
              </div>
              <div className="view_all">
                {this.props.viewAll && (
                  <Link to={this.props.viewAll} className="text_default">
                    <i className="linearicons-power"></i>
                    <span>View All</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider
              className={
                'SlickSliderWrapper SlickStyleDefault StickySylePrimaryStatic'
              }
              {...Object.assign({}, settings, this.props.settings || {})}
            >
              {this.state.products &&
                this.state.products.length > 0 &&
                this.state.products.map((item, index) => {
                  return this.props.type !== 'category' ? (
                    <Product
                      onClick={this.onClick}
                      key={`${this.state.key}${index}slider`}
                      data={item}
                      price={item.price}
                      index={index}
                      type={this.state.type || 'SingleProductInSlider'}
                      action={this.addToCart}
                      inQue={this.state.que.indexOf(item.url) > -1}
                      addToWishlist={this.wishlist}
                    />
                  ) : (
                    <CategoryBox
                      key={`${this.state.key}${index}slider`}
                      data={item}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ProductSection.propTypes = {
  name: PropTypes.string,
  tittle: PropTypes.string,
  getProductCategory: PropTypes.func,
  settings: PropTypes.object,
};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getProductCategory: (name) => {
    dispatch(productActions.products(name));
  },
  ajaxQuickView: (productId) => {
    return dispatch(productActions.ajaxQuickView(productId));
  },
  addToCart: (data) => {
    return dispatch(productActions.addTocart(data));
  },
  addToWishList : (data) => {
    return dispatch(productActions.addToWishList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSection);
