import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Helpers from '../../../util/helpers';
import { userActions } from '../../../actions/userActions';
import Modal from 'react-modal';
import {ConfirmationPopup} from '../../static/confirm-popup/ConfirmationPopup';
import {history} from '../../../store/configureStore';
class PartnerWithUs extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      pin: '',
      gst: '',
      business_name: '',
      phone: '',
      auth_error: '',
      ConfirmationPopup : false
    };
  }

  update = (event) => {
    let name = event.target.getAttribute('name') || null;
    if (!name) {
      return;
    }
    let allow = event.target.getAttribute('data-restrict');
    if (allow) {
      if (allow === 'number') {
        if (!Helpers.isNuber((event.target.value || '').trim())) {
          return;
        }
      }
    }

    this.setState({
      [name]: event.target.value || '',
    });
  };
  onFocusIn = (event) => {
    Helpers.focusIn(event);
  };
  onFocusOut = (event) => {
    Helpers.focusOut(event);
  };
  onInputClick = (event) => {
    Helpers.onClick(event);
  };
  submit = (event) => {
    event.preventDefault();
    if (Helpers.validateFrom(event.target)) {
      this.setState(
        {
          ajaxLoading: true,
        },
        () => {
          this.props.register({
            name: this.state.business_name,
            email: this.state.email,
            mobile: this.state.phone,
            gst_number: this.state.gst,
            pincode: this.state.pin,
          });
        }
      );
    } else {
    }
  };
  static getDerivedStateFromProps(nextProps) {
    const { auth, auth_error, user_register } = nextProps.user;
    if (auth && auth.id) {
      Helpers.updateUser(auth);
      return {
        user: auth,
      };
    }
    if (auth_error && auth_error.non_field_errors) {
      return {
        auth_error: auth_error.non_field_errors.join(''),
      };
    }
   
    if( user_register && user_register.status === 201 ){
      return {
        ConfirmationPopup : true ,
      }
    }
    return null;
  }
  getError() {
    if (!this.props.user.user_register) {
      return '';
    }
    let res = this.props.user.user_register.res;
    if (Object.keys(res).length > 0) {
      try {
        return res[Object.keys(res)[0]].join(' ');
      } catch (err) {
        return '';
      }
    }
    return '';
  }
  componentDidUpdate(prevProps, state) {
    if (state.ajaxLoading && this.props.user && this.props.user.user_register) {
      if (
        !prevProps.user.user_register ||
        this.props.user.user_register.attempt !==
          prevProps.user.user_register.attempt
      ) {
        this.setState({
          ajaxLoading: !1,
        });
      }
    }
  }
  close = () => {
    this.setState({
      ConfirmationPopup : !1
    },()=>{
      history.push('/');
    })
  }
  render() {
    if (this.state.user && this.state.user.id) {
      return <Redirect to={`/`} />;
    }
    return (
      <React.Fragment>
        <Modal isOpen={this.state.ConfirmationPopup} ariaHideApp={true}>
          <ConfirmationPopup onClose={this.close} />
        </Modal>
        <div className="login_register_wrap section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-md-10">
                <div className="login_wrap">
                  {this.state.ajaxLoading && (
                    <div className="UserAuthOverlay"></div>
                  )}
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3>Partner With us</h3>
                    </div>
                    <form
                      method="post"
                      noValidate={true}
                      onSubmit={this.submit}
                    >
                      <div className="form-group">
                        <input
                          id="business_name"
                          type="text"
                          required={true}
                          className="form-control"
                          name="business_name"
                          placeholder="Name of Business"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.business_name}
                          onClick={this.onInputClick}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="email"
                          type="text"
                          required={true}
                          className="form-control"
                          name="email"
                          placeholder="Email ID"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.email}
                          onClick={this.onInputClick}
                          data-type="email"
                        />
                      </div>

                      <div className="form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.phone}
                          required={true}
                          data-type="phone"
                          data-restrict="number"
                          maxLength={10}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="gst"
                          name="gst"
                          placeholder="GSTIN"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.gst}
                          required={true}
                          data-type="gst"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="pincode"
                          name="pin"
                          placeholder="Pincode"
                          onBlur={this.onFocusOut}
                          onFocus={this.onFocusIn}
                          onChange={this.update}
                          value={this.state.pin}
                          required={true}
                          data-type="pin"
                          data-restrict="number"
                          maxLength={6}
                        />
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form"></div>
                        <Link to="/login">Forgot password?</Link>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-fill-out btn-block"
                          name="login"
                        >
                          Proceed
                        </button>
                        {!this.state.ajaxLoading &&
                          this.props.user.user_register &&
                          this.props.user.user_register.status !== 201 && (
                            <div className="AuthError">{this.getError()}</div>
                          )}
                      </div>
                    </form>
                    <div className="different_login">
                      <span> or</span>
                    </div>
                    <div className="form-note text-center">
                      Already have an account?
                      <Link to="/login">Login now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
PartnerWithUs.propTypes = {};
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  register: (data) => {
    dispatch(userActions.register(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerWithUs);
