import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config/config';
export const Footer = (props) => {
  return (
    <footer className="bg_gray">
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget">
                <div className="footer_logo">
                  <Link to="/">
                    <img
                      src={require('../../../images/assets/logo-dark.png')}
                      alt="logo"
                    />
                  </Link>
                </div>
                <p>
                  If you are going to use of Lorem Ipsum need to be sure there
                  isn't hidden of text
                </p>
              </div>
              <div className="widget">
                <ul className="social_icons social_white">
                  <li>
                    <a href="#">
                      <i className="ion-social-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-googleplus"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-youtube-outline"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-instagram-outline"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="widget">
                <h6 className="widget_title">Category</h6>
                <ul className="widget_links">
                  {props.categories &&
                    props.categories.slice(0, 6).map((item,index) => {
                      return (
                        <li key={`Cat_link_one_${index}`}>
                          <Link to={`/products/${item.slug}`}>{item.name}</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="widget">
                <h6 className="widget_title">. . .</h6>
                <ul className="widget_links">
                  {props.categories &&
                    props.categories.slice(6, 12).map((item,index) => {
                      return (
                        <li  key={`Cat_link_two_${index}`}>
                          <Link to={`/products/${item.slug}`}>{item.name}</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="widget">
                <h6 className="widget_title">My Account</h6>
                <ul className="widget_links">
                  <li>
                    <Link to={`/my-account/profile`}>My Account</Link>
                  </li>
                  <li>
                    <Link to={`/my-orders/orders`}>Orders</Link>
                  </li>
                  <li>
                    <Link to={`/my-account/tracking`}>My Account</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="widget">
                <h6 className="widget_title">Contact Info</h6>
                <ul className="contact_info ">
                  <li>
                    <i className="ti-location-pin"></i>
                    <p>{config.meta.address}</p>
                  </li>
                  <li>
                    <i className="ti-email"></i>
                    <a href={`mailto:${config.meta.email}`}>
                      {config.meta.email}
                    </a>
                  </li>
                  <li>
                    <i className="ti-mobile"></i>
                    <p>{config.meta.phone}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_footer border-top-tran">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-md-0 text-center text-md-left">
                © {config.meta.year} All Rights Reserved by {config.meta.name}
              </p>
            </div>
            <div className="col-md-6">
              <ul className="footer_payment text-center text-lg-right">
                {/* <li><a href="#"><img src="assets/images/visa.png" alt="visa"/></a></li>
                        <li><a href="#"><img src="assets/images/discover.png" alt="discover"/></a></li>
                        <li><a href="#"><img src="assets/images/master_card.png" alt="master_card"/></a></li>
                        <li><a href="#"><img src="assets/images/paypal.png" alt="paypal"/></a></li>
                        <li><a href="#"><img src="assets/images/amarican_express.png" alt="amarican_express"/></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
