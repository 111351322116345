import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router' ;
import products from './product.reducer' ;
import user from './user.reducer' ;
import profile from './profile.reducer' ;
const rootReducer = history => combineReducers({
  products : products ,
  user : user,
  profile,
  router: connectRouter(history),
});
export default rootReducer;
