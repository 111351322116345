import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Helpers from '../../../util/helpers';
import { Link } from 'react-router-dom';
export class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
    };
  }
  updateSlider = (index) => {
    this.setState({
      activeSlide: index,
    });
  };
  render() {
    return (
      <div className="mt-4 staggered-animation-wrap">
        <div className="custom-container">
          <div className="row">
            <div className="col-lg-7 offset-lg-3">
              <div className="banner_section shop_el_slider">
                <div
                  id="carouselExampleControls"
                  className="carousel slide carousel-fade light_arrow"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <Slider
                      className={'SlickSliderWrapper SlickStyleDefault'}
                      {...{
                        dots: true,
                        infinite: true,
                        autoplay: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        fade: false,
                        afterChange: this.updateSlider,
                      }}
                    >
                      {this.props.offers &&
                        this.props.offers.length > 0 &&
                        this.props.offers.map((item, index) => {
                          return (
                            <div key={`offer_${index}`}>
                              <div
                                className={`carousel-item active background_bg ${
                                  this.state.activeSlide === index
                                    ? 'slideInLeft'
                                    : ''
                                }`}
                                style={{
                                  backgroundImage: `url(${item.banner})`,
                                }}
                              >
                                <div className="banner_slide_content banner_content_inner">
                                  <div className="col-lg-8 col-10">
                                    <div className="banner_content3 overflow-hidden">
                                      <h5
                                        className="mb-3 staggered-animation font-weight-light"
                                        data-animation="slideInLeft"
                                        data-animation-delay="0.5s"
                                      >
                                        #{item.offer.name}
                                      </h5>
                                      <h2
                                        className="staggered-animation"
                                        data-animation="slideInLeft"
                                        data-animation-delay="1s"
                                      >
                                        {item.name}
                                      </h2>
                                      <Link
                                        className="btn btn-fill-out rounded-0 staggered-animation text-uppercase"
                                        to={`/offers/${Helpers.getOfferUrl(
                                          item.url
                                        )}/`}
                                        data-animation="slideInLeft"
                                        data-animation-delay="1.5s"
                                      >
                                        Shop Now
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              {this.props.categories &&
                this.props.categories.slice(0, 2).map((item,index) => {
                  return (
                    <div key={`Banner_SideBox_${index}`} className={`BannerBoxSide shop_banner2 el_banner${index+1}`} style={{
                      backgroundImage : `url(${item.thumbnail_web_listing})`
                    }}>
                      <Link to={`/products/${item.slug}`} className="hover_effect1">
                        <div className="el_title text_white">
                          <span>{item.name}</span>
                          <h6>Shop Now</h6>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Banner.propTypes = {
  getOffers: PropTypes.func,
  offers: PropTypes.array,
};
const mapStateToProps = (state) => ({
  products: state.products,
});
const mapDispatchToProps = (dispatch) => ({
  getOffers: () => {
    dispatch(productActions.getOffers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
