import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../components/container/header/Header';
import { productActions } from '../actions/product.action';
import AjaxQuickView from './static/ajax-quick-view/AjaxQuickView';
import ProductDetailPage from './pages/product-detail/ProductDetailPage';
import Home from './pages/home/Home';
import Shop from './pages/shop/Shop';
import Login from './pages/login/Login';
import Quote from './pages/quote/Quote';
import PartnerWithUs from './pages/partner-with/PartnerWithUs';
import WishList from './static/wishlist/WishList';
import Cart from './static/cart/Cart';
import Checkout from './static/checkout/Checkout';
import ForceSignup from './static/force-signup/ForceSignup';
import { Footer } from './static/footer/Footer';
import Modal from 'react-modal';
import Helpers from '../util/helpers';
import { ToastContainer, toast } from 'react-toastify';
import PrivateRoute from './PrivateRoute';
import { history } from '../store/configureStore';
import MyAccount from './pages/my-account/MyAccount';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import config from '../config/config';
const TOAST_SETTINGS = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  type: 'dark',
};
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      ajax_quick_view: 0,
      forceSignup: !1,
      quote: !1,
    };
  }
  componentDidMount() {
    this.props.getCategories();
    Modal.setAppElement('body');
    !Helpers.getCurrentUser() &&
      !Helpers.forseSignupDisabled() &&
      setTimeout(() => {
        this.setState({
          forceSignup: !0,
        });
      }, 4000);
  }
  onForceSignupClose = (redirect = false) => {
    this.setState(
      {
        forceSignup: !1,
      },
      () => {
        redirect && history.push('/partner-with-us');
      }
    );
  };
  static getDerivedStateFromProps(nextProps, state) {
    const { products, user } = nextProps;
    if (
      products &&
      (products.get_quote == 0 || (products.get_quote && products.get_quote.id))
    ) {
      return {
        quote: products.get_quote,
      };
    }
    if (products && products.categories) {
      return {
        categories: products.categories,
      };
    }
    if (products && products.offers) {
      return {
        offers: products.offers,
      };
    }
    if (
      products &&
      products.ajax_quick_view &&
      products.ajax_quick_view.productId
    ) {
      return {
        ajax_quick_view: products.ajax_quick_view.productId,
      };
    }
    return null;
  }
  onClose = () => {
    this.setState({
      ajax_quick_view: 0,
    });
  };
  componentDidUpdate(prevProps) {
    if (
      `${this.props.location.pathname}${this.props.location.search}` !==
      `${prevProps.location.pathname}${prevProps.location.search}`
    ) {
      Helpers.scrollToY(0);
      Helpers.closeOpenItem();
    }
    if (this.props.user && this.props.user.toast) {
      if (
        !prevProps.user ||
        !prevProps.user.toast ||
        prevProps.user.toast.id !== this.props.user.toast.id
      ) {
        toast(this.props.user.toast.message || '', TOAST_SETTINGS);
      }
    }
    if (prevProps.user.auth !== this.props.user.auth) {
      if (!this.props.user.auth) {
        localStorage.removeItem('user');
      }
      toast(
        this.props.user.auth
          ? 'Successfully loggedin'
          : 'Successfully loggedout',
        TOAST_SETTINGS
      );
    }
    if (
      this.props.products &&
      this.props.products.wish_list_updated &&
      prevProps.products.wish_list_updated !==
        this.props.products.wish_list_updated
    ) {
      toast('Wish list updated', TOAST_SETTINGS);
    }
  }
  render() {
    return (
      <div id="wrapper" ref={(node) => (this.wrapper = node)} onClick={Helpers.appClick}>
        <Helmet>
            <title>Wood'n Cart | Online Furniture Shopping Store</title>
            <link
              rel="canonical"
              href={`${config.siteurl}`}
            />
<meta name="description" content="Online Furniture Shopping Store" />
<meta property="og:type" content="website" />
<meta property="og:url" content={`${config.siteurl}`} />
<meta property="og:title" content={`Wood'n Cart | Online Furniture Shopping Store`} />
<meta property="og:description" content="Online Furniture Shopping Store" />
<meta property="og:image" content="https://store.demo.fegno.com/src/cache/a3/6d/a36d8a629da96753d1d90bc60a57c006.jpg" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={`${config.siteurl}`} />
<meta property="twitter:description" content="Online Furniture Shopping Store" />
<meta property="twitter:title" content={`Wood'n Cart | Online Furniture Shopping Store`} />
<meta property="twitter:image" content="https://store.demo.fegno.com/src/cache/a3/6d/a36d8a629da96753d1d90bc60a57c006.jpg" />
          </Helmet>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.ajax_quick_view ? true : false}
        >
          <AjaxQuickView
            onClose={this.onClose}
            product={this.state.ajax_quick_view}
          />
        </Modal>
        <Modal ariaHideApp={false} isOpen={this.state.forceSignup}>
          {!Helpers.getCurrentUser() && (
            <ForceSignup onClose={this.onForceSignupClose} />
          )}
        </Modal>
        <Modal ariaHideApp={false} isOpen={this.state.quote ? true : false}>
          <Quote onClose={this.onQuoteClose} product={this.state.quote} />
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header
          limit={10}
          pathname={this.props.location.pathname}
          categories={this.state.categories}
        />
        <div className="contents-wrapper">
          <Switch>
            <PrivateRoute
              exact={true}
              path="/"
              component={Home}
              categories={this.state.categories}
            />
            <PrivateRoute exact={true} path="/login" component={Login} />
            <PrivateRoute path={`/partner-with-us`} component={PartnerWithUs} />
            <PrivateRoute
              path="/:type/:categoryName/"
              component={Shop}
              exact={true}
            />
            <PrivateRoute
              exact={true}
              path="/product/:slug/:id/"
              component={ProductDetailPage}
            />
            <PrivateRoute path="/user/cart/products/" component={Cart} />
            <PrivateRoute path="/user/cart/products/" component={Cart} />
            <PrivateRoute path="/checkout" component={Checkout} />
            <PrivateRoute
              path="/buy-now/checkout/:quantity/:productId"
              component={Checkout}
            />
            <PrivateRoute path="/user/wishlist/all/" component={WishList} />
            <PrivateRoute
              path="/user/my-account/:section"
              component={MyAccount}
            />
          </Switch>
        </div>
        <Footer categories={this.state.categories} />
      </div>
    );
  }
}
App.propTypes = {
  getCategories: PropTypes.func,
  getOffers: PropTypes.func,
};
const mapStateToProps = (state) => ({
  products: state.products,
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  getCategories: () => {
    dispatch(productActions.getCategories());
  },
  getOffers: () => {
    dispatch(productActions.getOffers());
  },
  closeQuote: (params) => {
    dispatch(productActions.quotePopup(params));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
