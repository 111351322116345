import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : languages.filter(
        (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};
const buildUrl = (suggestion) => {
  if (suggestion && suggestion.type === 'product') {
    return `/product/${suggestion.slug || 'slug'}/${suggestion.id}`;
  } else {
    return `/products/all/?q=${encodeURI(suggestion.title)}`;
  }
};
const getSuggestionValue = (suggestion) =>{
  return suggestion.title || '' ;
}
const renderSuggestion = (suggestion) => {
  return (
    <Link className="search_action" to={buildUrl(suggestion)}>
      <div className="search_item_wrapper">
        {suggestion.image && (
          <div className="search_item_thumb">
            <img src={suggestion.image} />
          </div>
        )}
        <div
          className={`search_item_details ${
            suggestion.image ? 'has-image' : ''
          }`}
        >
          <div className="search_item_name">{suggestion.title}</div>
          <div className="search_item_cat">
            in {suggestion.product_class_name}
          </div>
        </div>
      </div>
    </Link>
  );
};

export class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      suggestions: [],
    };
    this.timer = null;
  }
  search = () => {
    this.state.query && this.props.search(this.state.query);
    this.timer && clearTimeout(this.timer);
  };
  onChange = (event, { newValue }) => {
    this.setState({
      query: newValue,
    });
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(this.search, 500);
  };
  onSuggestionsFetchRequested = ({ value }) => {
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  }
  static getDerivedStateFromProps(nextProps) {
    let { _search } = nextProps.products;
    if (_search) {
      return {
        suggestions: _search.slice(0 , 6 ),
      };
    }
    return null;
  }
  selectedSearch = ()=>{
    
  }
  render() {
    const { query, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search',
      value: query,
      name: 'q',
      onChange: this.onChange,
    };
    return (
      <form
        method="GET"
        action={`/products/all/`}
        className={`SearchForm ${this.props.style || 'searchForm--default'}`}
        ref={(node)=>{this.node=node}}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          click={this.selectedSearch}   
        />
      </form>
    );
  }
}

SearchBox.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.products,
  };
}

const mapDispatchToProps = (dispatch) => ({
  search: (query) => {
    dispatch(productActions.search(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
