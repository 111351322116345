import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productActions } from '../../../actions/product.action';
const MAX_FOR_CHECKBOX = 50;
const FilterControl = (props) => {
  let val = props.data.val || [];
  return (
    <div className="widget">
      <h5 className="widget_title">{props.data.label}</h5>
      {val.length >= MAX_FOR_CHECKBOX && (
        <select
          onChange={props.onChange}
          name={props.data.code}
          className="form-control form-control-sm"
        >
          {val &&
            val.length > 0 &&
            val.map((option, index) => {
              if (!option.label) {
                return (
                  <React.Fragment key={`filter_${props.data.code}_${index}`} />
                );
              }
              return (
                <option
                  key={`filter_${props.data.code}_${index}`}
                  value={option.label}
                >
                  {option.label}
                </option>
              );
            })}
        </select>
      )}
      {val.length < MAX_FOR_CHECKBOX && (
        <ul className="list_brand">
          {val.map((item, index) => {
            if (!item.label) {
              return (
                <React.Fragment key={`filter_${props.data.code}_${index}`} />
              );
            }
            return (
              <li key={`filter_${props.data.code}_${index}`}>
                <div className="custome-checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={props.data.code}
                    id={`filter_${props.data.code}_${index}`}
                    value={item.label}
                    onChange={props.onChange}
                    checked={
                      (props.default || '')
                        .toString()
                        .split(',')
                        .indexOf((item.label || '').toString()) > -1
                        ? true
                        : false
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`filter_${props.data.code}_${index}`}
                  >
                    <span>{item.label}</span>
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_options: [],
      filter: {},
      id:
        this.props.filter && this.props.filter.id ? this.props.filter.id : null,
    };
  }
  componentDidMount() {
    if (this.state.id) {
      this.props.getFilterOptions(this.state.id);
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    let { filter } = nextProps;
    let { filter_options } = nextProps.products;

    if (filter && filter.id !== state.id) {
      return {
        id: filter.id,
      };
    }
    if (filter_options) {
      return {
        filter_options,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.filter &&
      prevProps.filter.id &&
      prevProps.filter.id !== this.state.id
    ) {
      this.updateFilter();
    }
  }
  updateFilter() {
    this.props.getFilterOptions(this.state.id);
  }
  getCheckBoxValues(node, name) {
    let siblings = document.querySelectorAll(`[name="${name}"]`),
      length = siblings.length,
      vals = [];
    for (let i = 0; i < length; i++) {
      if (siblings[i].checked) {
        vals.push(siblings[i].value);
      }
    }
    return vals;
  }
  applyFilter = () => {
    this.props.onChange &&
      typeof this.props.onChange === 'function' &&
      this.props.onChange(Object.assign({}, this.state.filter));
  };
  updateFilterObject(name, value) {
    this.setState(
      {
        filter: {
          ...Object.assign({}, this.state.filter),
          [name]: value,
        },
      },
      this.applyFilter
    );
  }
  onChange = (event) => {
    let target = event.target,
      node = (target.nodeName || '').toLowerCase(),
      type = target.getAttribute('type') || null;
    name = target.getAttribute('name');
    if (node === 'select') {
      this.updateFilterObject(name, target.value || '');
    }
    if ('checkbox' === type) {
      let val = this.getCheckBoxValues(target, name);
      this.updateFilterObject(name, val.join(','));
      return;
    }
  }
  componentDidUpdate(prevProps,prevState){
    if( prevProps.filter && prevProps.filter.id  ){
      if( prevProps.filter.id !== this.props.filter.id ){
        this.setState({
          filter : {}
        })
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.filter_options &&
          this.state.filter_options.map((item, index) => {
            let val = item.val || [];
            return (
              <FilterControl
                data={item}
                index={index}
                key={`FilterWidget_${this.props.tocken}_${index}_${item.code}`}
                onChange={this.onChange}
                default={
                  this.props.initial[item.code]
                    ? this.props.initial[item.code]
                    : null
                }
              />
            );
          })}
        {/* <div className="widget">
          <h5 className="widget_title">Size</h5>
          <div className="product_size_switch">
            <span>xs</span>
            <span>s</span>
            <span>m</span>
            <span>l</span>
            <span>xl</span>
            <span>2xl</span>
            <span>3xl</span>
          </div>
        </div> */}
        {/* <div className="widget">
          <h5 className="widget_title">Color</h5>
          <div className="product_color_switch">
            <span data-color="#87554B"></span>
            <span data-color="#333333"></span>
            <span data-color="#DA323F"></span>
            <span data-color="#2F366C"></span>
            <span data-color="#B5B6BB"></span>
            <span data-color="#B9C2DF"></span>
            <span data-color="#5FB7D4"></span>
            <span data-color="#2F366C"></span>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

Filter.propTypes = {
  filter: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    products: state.products,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFilterOptions: (id) => {
    dispatch(productActions.getFilter(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
