import { productService } from '../services/products.service';
export const productActions = {
  getCategories: (params) => (dispatch) => {
    productService
      .getCategories(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'GET_CATEGORIES',
          payload: res.result,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  search: (query) => (dispatch) => {
    productService
      .search(query)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'GLOBAL_SEARCH',
          payload: res.results,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  removeFromWishList: (params) => (dispatch) => {
    productService
      .removeFromWishList(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'WISH_LIST_UPDATED',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  addToWishList: (params) => (dispatch) => {
    productService
      .addToWishList(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'WISH_LIST_UPDATED',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  getWishList: (params) => (dispatch) => {
    productService
      .getWishList(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'GET_WISHLIST',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  getQuote: (params) => (dispatch) => {
    productService
      .getQuote(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'REQUEST_QUOTE',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  quotePopup: (params) => (dispatch) => {
    dispatch({
      type: 'QUOTE_POPUP',
      payload: params.id,
    });
  },
  checkout: (params) => (dispatch) => {
    productService
      .checkout(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'CHECKOUT',
          payload: res,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  getFilter: (id) => (dispatch) => {
    productService
      .getFilter(id)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'FILTER_OPTIONS',
          payload: res.results || [],
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  updateCart: (checkout = false) => (dispatch) => {
    productService
      .getCart()
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: checkout ? 'CHECKOUT_CART' : 'USER_CART',
          payload: res || null,
        });
      })
      .catch((status) => {
        console.log('Error', status);
      });
  },
  getProducts: (data) => (dispatch) => {
    productService
      .getProducts(data)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'GET_PORODUCTS',
          payload: { uid: new Date().getTime(), ...res },
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  getOfferProducts: (data) => (dispatch) => {
    productService
      .getOfferProducts(data)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'GET_PORODUCTS',
          payload: { uid: new Date().getTime(), ...res },
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  removeFromCart: (data) => (dispatch) => {
    productService
      .removeFromCart(data)
      .then((res) => {
        dispatch({
          type: 'REMOVE_FROM_CART',
          payload: { uid: new Date().getTime(), ...res },
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  addTocart: (data) => (dispatch) => {
    productService
      .addToCart(data)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'ADDED_TO_CART',
          payload: { target: data.url },
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  buyNow: (data) => (dispatch) => {
    productService
      .buyNow(data)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'CHECKOUT_CART',
          payload: res || null,
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  products: (name) => (dispatch) => {
    productService
      .products(name)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'CATEGORY_PRODUCTS',
          payload: { key: name, res: res },
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  ajaxQuickView: (id) => (dispatch) => {
    dispatch({
      type: 'AJAX_QUICK_VIEW',
      payload: { productId: id },
    });
  },
  getDetails: (id) => (dispatch) => {
    productService
      .detail(id)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'PRODUCT_DETAIL',
          payload: res.results,
        });
      })
      .catch((status) => {
        console.log(status);
      });
  },
  getOffers: (params) => (dispatch) => {
    productService
      .getOffers(params)
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: 'OFFERS',
          payload: res.offer_list,
        });
      })
      .catch((err) => {
        console.log(err, 'yeppiow');
      });
  },
};
