import config from '../config/config';
class Http {
  constructor() {
    this.api = config.api;
  }
  _url(path) {
    return `${this.api}${path}`;
  }
  post(url, params) {
    let h = new Headers({
      'Content-Type': 'application/json',
    });
    return this._(url, {
      method: 'POST',
      headers: h,
      credentials: 'include',
      body: JSON.stringify(params),
    });
  }
  delete(url, params = {} ,absoluteUrl = false) {
    let h =  new Headers({
      'Content-Type' : 'application/json'
    });
    return this._(
      url,
      {
        method: 'DELETE',
        headers : h ,
        credentials: 'include',
        body: JSON.stringify(params),
      },
      absoluteUrl
    );
  }
  patch(url, params = {}) {
    let h =  new Headers({
      'Content-Type' : 'application/json'
    });
    return this._(
      url,
      {
        method: 'PATCH',
        headers : h ,
        credentials: 'include',
        body: JSON.stringify(params),
      }
    );
  }
  shopFilter(params) {
    let queryString = '',
      added = 0;
    Object.keys(params).map((item) => {
      params[item] &&
        (params[item] || '') &&
        ((queryString += `${added == 0 ? 'filter=' : ''}${
          added > 0 ? '::' : ''
        }${item}:${params[item]}`),
        added++);
    });
    return queryString;
  }
  createUrlParams(params, hook) {
    if (hook && typeof this[hook] === 'function') {
      return this.shopFilter(params);
    }
    return '';
  }
  get(url, params, hook) {
    let payload = null;
    if (params) {
      payload = this.createUrlParams(params, hook);
    }
    return this._(`${url}${payload ? '?' + payload : ''}`, {
      method: 'GET',
      credentials: 'include',
      headers: {},
    });
  }
  handleResponse(resolve, reject, response) {
    let code = response.status,
      success = [200, 400, 204, 406, 201];
    if (success.indexOf(code) > -1) {
      return resolve(response);
    }
    reject(response);
  }
  httpFailed(resolve, reject, error) {
    reject(error);
  }
  _(url, payload, absoluteUrl) {
    let self = this;
    return new Promise((resolve, reject) => {
      fetch(!absoluteUrl ? this._url(url) : url, payload)
        .then((response) => {
          self.handleResponse.call(self, resolve, reject, response);
        })
        .catch((error) => {
          self.httpFailed.call(self, resolve, reject, error);
        });
    });
  }
}
export default new Http();
